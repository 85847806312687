import {
  CloseOutlined,
  FormOutlined,
  LoginOutlined,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import {
  Button,
  Card,
  Col,
  Row,
  Table,
  Typography,
  Space,
  Tooltip,
  Input,
  Form,
} from 'antd'
import { first, isEmpty } from 'lodash'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AddChannelPopup } from 'src/components/program/AddChannelPopup'
import { CreateProgramDrawer } from 'src/components/program/CreateProgramDrawer'
import { DeleteProgramModal } from 'src/components/program/DeleteProgramPopup'
import { PreviewVod } from 'src/components/vod/PreviewVod'
import { getDuration } from 'src/helpers/time'
import {
  createProgram,
  deleteProgram,
  getListProgram,
} from 'src/store/slice/program'

export const Program = (props) => {
  const {
    data: { items = [], meta },
  } = useSelector((state) => state.programs)
  const [openCreateProgram, setOpenCreateProgram] = useState(false)
  const [page, setPage] = useState(meta?.page || 1)
  const [limit, setLimit] = useState(20)
  const [openModalDelete, setOpenModalDelete] = useState(false)
  const [programSelected, setProgramSelected] = useState(null)
  const [openModalAddChannel, setOpenModalAddChannel] = useState(false)
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  useEffect(() => {
    fetchData(page, limit)
  }, [page, limit])

  const fetchData = (page, limit, filter) => {
    const params = { page: page, limit: limit }
    if (!isEmpty(filter)) {
      params.filter = JSON.stringify(filter)
    }
    dispatch(getListProgram(params))
  }

  const onSubmitDelete = () => {
    dispatch(
      deleteProgram({
        id: programSelected?.id,
        onSuccess: () => {
          fetchData(page, limit)
          setOpenModalDelete(false)
        },
        onFailed: () => setOpenModalDelete(false),
      }),
    )
  }

  const handleClickDelete = (program) => {
    setProgramSelected(program)
    setOpenModalDelete(true)
  }

  const columns = [
    {
      title: 'NAME',
      dataIndex: 'name',
      key: 'name',
      width: '32%',
    },
    {
      title: 'ASSET',
      key: 'asset',
      dataIndex: 'asset',
    },
    {
      title: 'DESCRIPTION',
      dataIndex: 'description',
      key: 'description',
      width: '32%',
    },
    {
      title: 'DURATION',
      key: 'duration',
      dataIndex: 'duration',
    },
    {
      title: 'ACTION',
      key: 'action',
      dataIndex: 'action',
    },
  ]

  const handleClickAddChannel = (program) => {
    setProgramSelected(program)
    setOpenModalAddChannel(true)
  }

  const data = items?.map((program, index) => {
    return {
      key: index,
      name: program?.name,
      asset: <PreviewVod id={program?.vodId} vodSelected={program?.vod} />,
      description: program?.description,
      duration: getDuration(program?.vod?.duration || 0),
      action: (
        <Space gap="middle">
          <Tooltip
            title="Add Channel"
            onClick={() => handleClickAddChannel(program)}
          >
            <Button>
              <LoginOutlined /> Channel
            </Button>
          </Tooltip>
          <Tooltip title="Edit">
            <Button type="primary">
              <FormOutlined /> edit
            </Button>
          </Tooltip>
          <Tooltip title="Delete">
            <Button type="danger" onClick={() => handleClickDelete(program)}>
              <CloseOutlined /> delete
            </Button>
          </Tooltip>
        </Space>
      ),
    }
  })

  const onClickCreateChannel = () => {
    setOpenCreateProgram(true)
  }

  const onSubmitCreateChannel = (values) => {
    const data = {
      name: values?.name,
      vodId: values?.vod,
      watermarks: values?.watermarks?.map((watermark) => {
        return {
          name: watermark?.name,
          delay: watermark?.delay,
          status: +watermark?.status,
          displayType: watermark?.displayType,
          imageSize: watermark?.mediaScale,
          positionX: watermark?.x,
          positionY: watermark?.y,
          imageIndex: watermark?.zIndex,
          image: first(watermark?.asset?.fileList)?.thumbUrl,
        }
      }),
    }
    dispatch(
      createProgram({
        data,
        onSuccess: () => {
          fetchData(page, limit)
          setOpenCreateProgram(false)
        },
      }),
    )
  }

  const onSearch = (values) => {
    const filters = []
    for (const i in values) {
      if (values[i]) {
        filters.push({
          column: i,
          text: values[i],
        })
      }
    }
    fetchData(page, limit, filters)
  }

  return (
    <div className="tabled">
      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        <Form layout="horizontal" onFinish={onSearch} form={form}>
          <Row gutter={[24, 24]} style={{ marginTop: '5px' }}>
            <Col span={24}>
              <Typography.Title level={4}>Danh sách Program</Typography.Title>
            </Col>
            <Col span={4}>
              <Form.Item name="name" label="Tên programs">
                <Input />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item name="tags" label="Tags">
                <Input />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Button icon={<SearchOutlined />} onClick={() => form.submit()}>
                Search
              </Button>
            </Col>
            <Col span={3} offset={11}>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={onClickCreateChannel}
              >
                Thêm mới Program
              </Button>
            </Col>
          </Row>
        </Form>

        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card bordered={false} className="criclebox tablespace mb-24">
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={data}
                  pagination={{
                    onChange: (page, pageSize) => {
                      setPage(page)
                      setLimit(pageSize)
                    },
                    hideOnSinglePage: true,
                    pageSize: limit,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '30'],
                    total: meta?.total,
                  }}
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
        </Row>
      </Space>
      <AddChannelPopup
        open={openModalAddChannel}
        handleClose={() => {
          setOpenModalAddChannel(false)
          props.reloadChannel && props.reloadChannel()
        }}
        program={programSelected}
        channelId={props.channelId}
      />
      <CreateProgramDrawer
        open={openCreateProgram}
        onClose={() => setOpenCreateProgram(false)}
        onSubmit={onSubmitCreateChannel}
      />
      <DeleteProgramModal
        open={openModalDelete}
        handleOk={onSubmitDelete}
        handleCancel={() => {
          setProgramSelected(null)
          setOpenModalDelete(false)
        }}
        program={programSelected}
      />
    </div>
  )
}
