export const API_URL = {
  AUTH: {
    LOGIN: 'auth/login',
  },
  VOD: {
    LIST: 'vods/list',
    CREATE: 'vods/create',
    UPDATE: 'vods/update',
    DETAIL: 'vods/:id/detail',
    PREVIEW: 'vods/:id/preview',
    DELETE: 'vods/:id/remove',
  },
  CHANNEL: {
    LIST: 'channels/list',
    CREATE: 'channels/create',
    UPDATE: 'channels/update',
    DETAIL: 'channels/:id/detail',
    DETAIL_PROGRAM: 'channels/:id/detail/programs',
    DELETE: 'channels/:id/remove',
    ADD_PROGRAM: 'programs/add-channel',
    UPDATE_PROGRAM: 'channels/update/program',
    DELETE_PROGRAM: 'channels/:id/delete/program'
  },
  PROGRAM: {
    LIST: 'programs/list',
    CREATE: 'programs/create',
    UPDATE: 'programs/update',
    DETAIL: 'programs/:id/detail',
    DELETE: 'programs/:id/remove',
    ADD_CHANNEL: 'programs/add-channel'
  },
}
