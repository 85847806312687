import { createSlice } from '@reduxjs/toolkit'
import { SLICE_VOD } from './type'

const vodInitialState = {
  data: { items: [], meta: { page: 1, total: 1 } },
  isLoading: false,
  errors: null,
}

export const vodSlice = createSlice({
  name: SLICE_VOD,
  initialState: vodInitialState,
  reducers: {
    getListVod: (state) => {
      return {
        ...state,
        isLoading: true,
        errors: null,
      }
    },
    getListVodSuccess: (_, { payload }) => {
      return {
        data: payload,
        isLoading: false,
        errors: null,
      }
    },
    getListVodFailed: (state) => {
      return {
        ...state,
        isLoading: false,
        errors: 'Có lỗi xả xảy ra vui lòng thử lại',
      }
    },
    deleteVod: (state) => {
      return {
        ...state,
        isLoading: true,
        errors: null,
      }
    },
    deleteVodSuccess: (state) => {
      return {
        ...state,
        isLoading: false,
        errors: null,
      }
    },
    deleteVodFailed: (state) => {
      return {
        ...state,
        isLoading: false,
        errors: 'Có lỗi xảy ra vui lòng thử lại',
      }
    },
    createVod: (state) => {
      return {
        ...state,
        isLoading: true,
        errors: null,
      }
    },
    createVodSuccess: (state) => {
      return {
        ...state,
        isLoading: false,
        errors: null,
      }
    },
    createVodFailed: (state) => {
      return {
        ...state,
        isLoading: false,
        errors: 'Có lỗi xảy ra vui lòng thử lại',
      }
    },
    updateVod: (state) => {
      return {
        ...state,
        isLoading: true,
        errors: null,
      }
    },
    updateVodSuccess: (state) => {
      return {
        ...state,
        isLoading: false,
        errors: null,
      }
    },
    updateVodFailed: (state) => {
      return {
        ...state,
        isLoading: false,
        errors: 'Có lỗi xảy ra vui lòng thử lại',
      }
    },
  },
})

export const {
  getListVod,
  getListVodSuccess,
  getListVodFailed,
  deleteVod,
  deleteVodSuccess,
  deleteVodFailed,
  createVod,
  createVodSuccess,
  createVodFailed,
  updateVod,
  updateVodSuccess,
  updateVodFailed,
} = vodSlice.actions
export default vodSlice.reducer
