import { CloseOutlined, FormOutlined, UploadOutlined } from '@ant-design/icons'
import {
  Button,
  Card,
  Col,
  Radio,
  Row,
  Table,
  Space,
  Tooltip,
  Typography,
} from 'antd'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { ChannelCreateDrawer } from 'src/components/channel/ChannelCreateDrawer'
import { ChannelModalDelete } from 'src/components/channel/ChannelPopupDelete'
import {
  channelStatus,
  channelStatusMap,
} from 'src/components/channel/constant'
import {
  createChannel,
  deleteChannel,
  getListChannel,
} from 'src/store/slice/channel'

export const Channel = () => {
  const {
    data: { items = [], meta },
  } = useSelector((state) => state.channels)

  const [page, setPage] = useState(meta?.page || 1)
  const [limit, setLimit] = useState(10)
  const [openModalDelete, setOpenModalDelete] = useState(false)
  const [channelSelected, setChannelSelected] = useState(null)
  const [channelCreateOpen, setChannelCreateOpen] = useState(null)
  const dispatch = useDispatch()
  useEffect(() => {
    fetchData(page, limit)
  }, [page, limit])

  const fetchData = (page, limit) => {
    dispatch(getListChannel({ page: page, limit: limit }))
  }

  const onChange = () => {}

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'CHANNEL NAME',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'DESCRIPTION',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'CREATED AT',
      key: 'createdAt',
      dataIndex: 'createdAt',
    },
    {
      title: 'Actions',
      key: 'action',
      dataIndex: 'action',
    },
  ]

  const handleClickDelete = (channel) => {
    setChannelSelected(channel)
    setOpenModalDelete(true)
  }

  const handleCancelDelete = () => {
    setChannelSelected(null)
    setOpenModalDelete(false)
  }

  const handleOkDelete = () => {
    dispatch(
      deleteChannel({
        id: channelSelected?.id,
        onSuccess: () => {
          fetchData(page, limit)
          setOpenModalDelete(false)
          setChannelSelected(null)
        },
        onFailed: () => {
          setOpenModalDelete(false)
          setChannelSelected(null)
        },
      }),
    )
  }

  const onClickCreateChannel = () => {
    setChannelSelected(null)
    setChannelCreateOpen(true)
  }

  const onSubmitCreateChannel = (values) => {
    dispatch(
      createChannel({
        data: values,
        onSuccess: () => {
          fetchData(page, limit)
          setChannelCreateOpen(false)
        },
      }),
    )
  }

  const data = items?.map((item, index) => ({
    ...item,
    id: index + 1,
    name: <Link to={`/channel/${item?.id}/detail`}>{item?.name}</Link>,
    status: (
      <Button
        type={item?.status === +channelStatus.online ? 'primary' : 'ghost'}
      >
        {channelStatusMap[item?.status]}
      </Button>
    ),
    createdAt: moment(item.createdAt).format('DD-MM-YYYY HH:mm:ss'),
    action: (
      <Space gap="middle">
        <Tooltip title="Edit">
          <Link to={`/channel/${item?.id}/edit`}>
            <Button type="primary">
              <FormOutlined /> edit
            </Button>
          </Link>
        </Tooltip>
        <Tooltip title="Delete">
          <Button type="danger" onClick={() => handleClickDelete(item)}>
            <CloseOutlined /> delete
          </Button>
        </Tooltip>
      </Space>
    ),
  }))

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col span={12}>
          <Typography.Title level={4}>Danh sách Channel</Typography.Title>
        </Col>
        <Col span={3} offset={9}>
          <Button
            type="primary"
            icon={<UploadOutlined />}
            onClick={onClickCreateChannel}
          >
            Thêm mới Channel
          </Button>
        </Col>
      </Row>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              extra={
                <>
                  <Radio.Group onChange={onChange} defaultValue="a">
                    <Radio.Button value="all">All</Radio.Button>
                    <Radio.Button value="online">ONLINE</Radio.Button>
                  </Radio.Group>
                </>
              }
            >
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={data || []}
                  pagination={{
                    onChange: (page, pageSize) => {
                      setPage(page)
                      setLimit(pageSize)
                    },
                    hideOnSinglePage: true,
                    pageSize: limit,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '30'],
                    total: meta?.total
                  }}
                  className="ant-border-space"
                  rowKey={'id'}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
      <ChannelModalDelete
        channel={channelSelected}
        open={openModalDelete}
        handleCancel={handleCancelDelete}
        handleOk={handleOkDelete}
      />
      <ChannelCreateDrawer
        open={channelCreateOpen}
        onClose={() => setChannelCreateOpen(false)}
        onSubmit={onSubmitCreateChannel}
      />
    </>
  )
}
