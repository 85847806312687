import { call, put, takeLatest } from 'redux-saga/effects'
import {
  createChannelError,
  deleteChannelError,
  getDetailChannelError,
  getDetailChannelProgramError,
  getDetailChannelProgramSuccess,
  getDetailChannelSuccess,
  getListChannelError,
  getListChannelSuccess,
  updateChannelError,
} from 'src/store/slice/channel'
import {
  CREATE_CHANNEL,
  DELETE_CHANNEL,
  DELETE_CHANNEL_PROGRAM,
  DETAIL_CHANNEL,
  DETAIL_CHANNEL_PROGRAM,
  GET_LIST_CHANNEL,
  UPDATE_CHANNEL,
  UPDATE_CHANNEL_PROGRAM,
  ADD_CHANNEL_PROGRAM,
} from 'src/store/slice/channel/type'
import { api } from 'src/services/api'
import { API_URL } from 'src/services/api/constant'
import addNotification, { NOTIFICATION_TYPE } from 'src/utils/toast'

/* start list channel */
export const apiGetListChannel = (params) => {
  return api.get(API_URL.CHANNEL.LIST, params)
}

function* doGetListChannel({ payload }) {
  try {
    const response = yield call(apiGetListChannel, payload)
    if (!response?.data) {
      yield put(getListChannelError())
    }
    yield put(getListChannelSuccess(response.data))
    if (payload.onSuccess) {
      yield payload.onSuccess()
    }
  } catch (error) {
    yield put(getListChannelError(error))
  }
}
/* end list channel */

/* start create channel */
const apiCreateChannel = (params) => {
  return api.post(API_URL.CHANNEL.CREATE, params)
}

function* doCreateChannel({ payload }) {
  try {
    const response = yield call(apiCreateChannel, payload.data)
    if (response?.statusCode === 200) {
      addNotification('Tạo channel thành công', NOTIFICATION_TYPE.SUCCESS)
      if (payload.onSuccess) {
        yield payload.onSuccess()
      }
    }
    if (response?.statusCode !== 200) {
      addNotification('Có lỗi xảy ra vui lòng thử lại', NOTIFICATION_TYPE.ERROR)
      yield put(createChannelError())
    }
  } catch (error) {
    yield put(createChannelError(error))
  }
}
/* end create channel */

/* start update channel */
const apiUpdateChannel = (params) => {
  return api.put(API_URL.CHANNEL.UPDATE, params)
}

function* doUpdateChannel({ payload }) {
  try {
    const response = yield call(apiUpdateChannel, payload.data)
    if (response?.statusCode === 200) {
      addNotification('Cập nhật channel thành công', NOTIFICATION_TYPE.SUCCESS)
      if (payload.onSuccess) {
        yield payload.onSuccess()
      }
    }
    if (response?.statusCode !== 200) {
      addNotification(
        response?.message || 'Có lỗi xảy ra vui lòng thử lại',
        NOTIFICATION_TYPE.ERROR,
      )
      yield put(updateChannelError())
    }
  } catch (error) {
    yield put(updateChannelError(error))
  }
}
/* end update channel */

/* start update channel */
const apiUpdateChannelProgram = (params) => {
  return api.put(API_URL.CHANNEL.UPDATE_PROGRAM, params)
}

function* doUpdateChannelProgram({ payload }) {
  try {
    const response = yield call(apiUpdateChannelProgram, payload.data)
    if (response?.statusCode === 200) {
      addNotification('Cập nhật program thành công', NOTIFICATION_TYPE.SUCCESS)
      if (payload.onSuccess) yield payload.onSuccess()
    }
    if (response?.statusCode !== 200) {
      addNotification(
        response?.message || 'Có lỗi xảy ra vui lòng thử lại',
        NOTIFICATION_TYPE.ERROR,
      )
      if (payload.onFailed) yield payload.onFailed()
      yield put(updateChannelError())
    }
  } catch (error) {
    yield put(updateChannelError(error))
  }
}
/* end update channel */

/* start delete channel */
const apiDeleteChannel = (params) => {
  return api.delete(API_URL.CHANNEL.DELETE.replace(':id', params?.id))
}

function* doDeleteChannel({ payload }) {
  try {
    const response = yield call(apiDeleteChannel, payload)
    if (response?.statusCode === 200) {
      addNotification('Xoá channel thành công', NOTIFICATION_TYPE.SUCCESS)
      if (payload.onSuccess) {
        yield payload.onSuccess()
      }
    }
    if (response?.statusCode !== 200) {
      addNotification('Có lỗi xảy ra vui lòng thử lại', NOTIFICATION_TYPE.ERROR)
      yield put(deleteChannelError())
    }
  } catch (error) {
    yield put(deleteChannelError(error))
  }
}
/* end delete channel */

/* start detail channel */
const apiGetDetailChannel = (params) => {
  return api.get(API_URL.CHANNEL.DETAIL.replace(':id', params?.id))
}

function* doGetDetailChannel({ payload }) {
  try {
    const response = yield call(apiGetDetailChannel, payload)
    if (response?.statusCode === 200) {
      yield put(getDetailChannelSuccess(response?.data))
      if (payload.onSuccess) {
        yield payload.onSuccess(response)
      }
    }
    if (response?.statusCode !== 200) {
      addNotification('Có lỗi xảy ra vui lòng thử lại', NOTIFICATION_TYPE.ERROR)
      yield put(getDetailChannelError())
    }
  } catch (error) {
    yield put(getDetailChannelError(error))
  }
}
/* end detail channel */

/* start detail channel programs by date */
const apiGetDetailChannelProgramByDate = (params) => {
  return api.get(
    API_URL.CHANNEL.DETAIL_PROGRAM.replace(':id', params?.id),
    params,
  )
}

function* doGetDetailChannelProgramByDate({ payload }) {
  try {
    const response = yield call(apiGetDetailChannelProgramByDate, payload)
    if (response?.statusCode === 200) {
      yield put(getDetailChannelProgramSuccess(response?.data))
      if (payload.onSuccess) {
        yield payload.onSuccess(response)
      }
    }
    if (response?.statusCode !== 200) {
      addNotification('Có lỗi xảy ra vui lòng thử lại', NOTIFICATION_TYPE.ERROR)
      yield put(getDetailChannelProgramError())
    }
  } catch (error) {
    yield put(getDetailChannelProgramError(error))
  }
}

const apiDeleteChannelProgram = (params) => {
  return api.delete(API_URL.CHANNEL.DELETE_PROGRAM.replace(':id', params?.id))
}

function* doDeleteChannelProgram({ payload }) {
  try {
    const response = yield call(apiDeleteChannelProgram, payload)
    if (response?.statusCode === 200) {
      addNotification('Xoá program thành công', NOTIFICATION_TYPE.SUCCESS)
      if (payload.onSuccess) yield payload.onSuccess()
    }
    if (response?.statusCode !== 200) {
      addNotification(
        response?.message || 'Có lỗi xảy ra vui lòng thử lại',
        NOTIFICATION_TYPE.ERROR,
      )
      if (payload.onFailed) yield payload.onFailed()
    }
  } catch (error) {
    // yield put(deleteProgramError(error))
  }
}

const apiAddChannelProgram = (payload) => {
  return api.put(API_URL.CHANNEL.ADD_PROGRAM, payload)
}

function* doAddChannelProgram({ payload }) {
  try {
    const response = yield call(apiAddChannelProgram, payload.data)
    if (response?.statusCode === 200) {
      addNotification('Thêm program thành công', NOTIFICATION_TYPE.SUCCESS)
      if (payload.onSuccess) yield payload.onSuccess()
    }
    if (response?.statusCode !== 200) {
      addNotification(
        response?.message || 'Có lỗi xảy ra vui lòng thử lại',
        NOTIFICATION_TYPE.ERROR,
      )
      if (payload.onFailed) yield payload.onFailed()
    }
  } catch (error) {
    // yield put(deleteProgramError(error))
  }
}

export function* watchDoGetListChannel() {
  yield takeLatest(GET_LIST_CHANNEL, doGetListChannel)
}

export function* watchDoCreateChannel() {
  yield takeLatest(CREATE_CHANNEL, doCreateChannel)
}

export function* watchDoDeleteChannel() {
  yield takeLatest(DELETE_CHANNEL, doDeleteChannel)
}

export function* watchDoGetDetailChannel() {
  yield takeLatest(DETAIL_CHANNEL, doGetDetailChannel)
}

export function* watchDoUpdateChannel() {
  yield takeLatest(UPDATE_CHANNEL, doUpdateChannel)
}

export function* watchDoGetDetailChannelProgram() {
  yield takeLatest(DETAIL_CHANNEL_PROGRAM, doGetDetailChannelProgramByDate)
}

export function* watchDoUpdateChannelProgram() {
  yield takeLatest(UPDATE_CHANNEL_PROGRAM, doUpdateChannelProgram)
}

export function* watchDoDeleteChannelProgram() {
  yield takeLatest(DELETE_CHANNEL_PROGRAM, doDeleteChannelProgram)
}

export function* watchDoAddChannelProgram() {
  yield takeLatest(ADD_CHANNEL_PROGRAM, doAddChannelProgram)
}