import { Modal, Typography } from 'antd'

export const ChannelModalDelete = (props) => {
  const { open, handleOk, handleCancel, channel } = props
  return (
    <Modal
      title="Xoá Channel"
      open={open}
      onOk={handleOk}
      onCancel={handleCancel}
      okText="Delete"
      cancelText="Cancel"
    >
      Bạn có chắc chắn muốn xoá{' '}
      <Typography.Text level={4} strong={true}>
        {channel?.name}
      </Typography.Text>{' '}
      không?
    </Modal>
  )
}
