import { Col } from 'antd'
import { VodCard } from './VodCard'

export const CardVodView = (props) => {
  const { vods, handleClickDelete, handleClickVod } = props

  return (
    <>
      {vods.map((vod) => (
        <Col span={4}>
          <VodCard
            key={vod.id}
            data={vod}
            handleClickDelete={handleClickDelete}
            handleClickVod={handleClickVod}
          />
        </Col>
      ))}
    </>
  )
}
