import { Col, DatePicker, Form, Modal, Row, Select, TimePicker } from 'antd'
import { debounce, isNil } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  getProgramEndTimeByStartTimeAndDuration,
  splitTime,
} from '../channel/SliceProgram/helpers/format'
import { addChannelProgram } from 'src/store/slice/program'
import { apiGetListChannel } from 'src/store/sagas/channel'

export const AddChannelPopup = (props) => {
  const { open, handleClose, program } = props
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [channels, setChannels] = useState([])

  useEffect(() => {
    getChannels()
  }, [1, 20])

  const debounceDropDown = useCallback(
    debounce((e) => getChannels(e), 1000),
    [],
  )

  const getChannels = async (keyword) => {
    const payload = { page: 1, limit: 20 }
    if (!isNil(keyword)) payload.keyword = keyword
    const response = await apiGetListChannel(payload)
    if (response?.statusCode === 200 && response?.data) {
      setChannels(response?.data?.items)
    }
  }

  const onSubmitAddChannel = (values) => {
    const { channelId, date, startTime } = values
    const endTime = getProgramEndTimeByStartTimeAndDuration(
      startTime,
      program.vod.duration,
    )
    const [fromHour, fromMinute, fromSecond] = splitTime(startTime)
    const [toHour, toMinute, toSecond] = splitTime(endTime)
    const data = {
      channelId: channelId,
      programId: program.id,
      date: date.format('YYYY-MM-DD'),
      from: date.hour(fromHour).minute(fromMinute).second(fromSecond),
      to: date.hour(toHour).minute(toMinute).second(toSecond),
    }
    dispatch(
      addChannelProgram({
        data: data,
        onSuccess: () => {
          handleClose()
          form.resetFields()
        },
      }),
    )
  }

  return (
    <Modal
      title="Add Channel"
      open={open}
      onOk={() => form.submit()}
      onCancel={() => {
        handleClose()
        form.resetFields()
      }}
      okText="Save"
      cancelText="Cancel"
    >
      <Form
        onFinish={onSubmitAddChannel}
        form={form}
        labelCol={{ span: 7 }}
        wrapperCol={{ span: 16 }}
      >
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item name="program" label="program" required>
              {program?.name}
            </Form.Item>
            <Form.Item
              name="channelId"
              label="Channel"
              rules={[{ required: true, message: 'Chọn channel' }]}
              valuePropName="option"
            >
              <Select
                onSearch={(e) => debounceDropDown(e)}
                allowClear
                showSearch
              >
                {channels?.map((channel) => (
                  <Select.Option value={channel.id} key={channel.id}>
                    {channel.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="date"
              label="Ngày"
              required
              rules={[{ required: true, message: 'Chọn ngày phát' }]}
            >
              <DatePicker allowClear />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="startTime"
              label="Thời gian bắt đầu"
              rules={[{ required: true, message: 'Chọn thời gian bắt đầu' }]}
            >
              <TimePicker allowClear />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}
