import {
  PlayCircleOutlined,
  RobotOutlined,
  VideoCameraAddOutlined,
} from '@ant-design/icons'
import { Vod } from 'src/pages/vod/Index'
import { Channel } from 'src/pages/channel/Index'
import { DetailChannel } from 'src/pages/channel/Detail'
import { Program } from 'src/pages/program/Index'
import SignIn from 'src/pages/SignIn'
import SignUp from 'src/pages/SignUp'
import { NotFound } from 'src/pages/notFound/Index'
import { EditChannel } from 'src/pages/channel/Edit'

export const routes = [
  {
    path: '404',
    name: 'Not Found',
    component: NotFound,
    showInSidebar: false,
    isPublic: true,
  },
  {
    path: 'sign-in',
    name: 'Đăng nhập',
    component: SignIn,
    showInSidebar: false,
    isPublic: true,
  },
  {
    path: 'sign-up',
    name: 'Đăng kí',
    component: SignUp,
    showInSidebar: false,
    isPublic: true,
  },
  {
    path: 'vod',
    name: 'Quản lý vod',
    component: Vod,
    icon: <VideoCameraAddOutlined />,
    showInSidebar: true,
  },
  {
    path: 'channel',
    name: 'Quản lý channel',
    component: Channel,
    icon: <PlayCircleOutlined />,
    showInSidebar: true,
  },
  {
    path: 'channel/:id/detail',
    name: 'Chi tiết channel',
    component: DetailChannel,
    showInSidebar: false,
  },
  {
    path: 'channel/:id/edit',
    name: 'Sửa channel',
    component: EditChannel,
    showInSidebar: false,
  },
  {
    path: 'program',
    name: 'Quản lý program',
    component: Program,
    icon: <RobotOutlined />,
    showInSidebar: true,
  },
]
