import { createSlice } from '@reduxjs/toolkit'
import { SLICE_PROGRAM } from './type'

const programInitialState = {
  data: { items: [], meta: { page: 1, total: 1 } },
  isLoading: false,
  errors: null,
}

export const programSlice = createSlice({
  name: SLICE_PROGRAM,
  initialState: programInitialState,
  reducers: {
    getListProgram: (state) => {
      return {
        ...state,
        isLoading: true,
        errors: null,
      }
    },
    getListProgramSuccess: (_, { payload }) => {
      return {
        data: payload,
        isLoading: false,
        errors: null,
      }
    },
    getListProgramError: (state) => {
      return {
        ...state,
        isLoading: false,
        errors: 'Có lỗi xảy ra vui lòng thử lại',
      }
    },
    createProgram: (state) => {
      return {
        ...state,
        isLoading: true,
        errors: null,
      }
    },
    createProgramSuccess: (_, { payload }) => {
      return {
        data: payload,
        isLoading: false,
        errors: null,
      }
    },
    createProgramError: (state) => {
      return {
        ...state,
        isLoading: false,
        errors: 'Có lỗi xảy ra vui lòng thử lại',
      }
    },
    deleteProgram: (state) => {
      return {
        ...state,
        isLoading: true,
        errors: null,
      }
    },
    deleteProgramSuccess: (_, { payload }) => {
      return {
        data: payload,
        isLoading: false,
        errors: null,
      }
    },
    deleteProgramError: (state) => {
      return {
        ...state,
        isLoading: false,
        errors: 'Có lỗi xảy ra vui lòng thử lại',
      }
    },
    getDetailProgram: (state) => {
      return {
        ...state,
        isLoading: true,
        errors: null,
      }
    },
    getDetailProgramSuccess: (_, { payload }) => {
      return {
        detail: payload,
        data: payload,
        isLoading: false,
        errors: null,
      }
    },
    getDetailProgramError: (state) => {
      return {
        ...state,
        isLoading: false,
        errors: 'Có lỗi xảy ra vui lòng thử lại',
      }
    },
    addChannelProgram: (state) => {
      return {
        ...state,
        isLoading: true,
        errors: null,
      }
    },
    addChannelProgramSuccess: (_, { payload }) => {
      return {
        data: payload,
        isLoading: false,
        errors: null,
      }
    },
    addChannelProgramError: (state) => {
      return {
        ...state,
        isLoading: false,
        errors: 'Có lỗi xảy ra vui lòng thử lại',
      }
    },
  },
})

export const {
  getListProgram,
  getListProgramSuccess,
  getListProgramError,
  createProgram,
  createProgramSuccess,
  createProgramError,
  deleteProgram,
  deleteProgramError,
  deleteProgramSuccess,
  getDetailProgram,
  getDetailProgramSuccess,
  getDetailProgramError,
  addChannelProgram,
  addChannelProgramSuccess,
  addChannelProgramError
} = programSlice.actions
export default programSlice.reducer
