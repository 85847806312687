export const vodStatus = {
  completed: "completed",
  inprogress: "inprogress",
}

export const vodStatusOptions = [
  {
    label: 'completed',
    value: vodStatus.completed
  },
  {
    label: 'inprogress',
    value: vodStatus.inprogress
  }
]

export const vodStatusColor = {
  [vodStatus.inprogress]: '#2db7f5',
  [vodStatus.completed]: '#87d068',
}

export const vodType = {
  film: "Film",
  gameShow: "GameShow",
  tvc: 'TVC'
}

export const vodTypeOptions = [
  {
    label: 'Film',
    value: vodType.film
  },
  {
    label: 'Game show',
    value: vodType.gameShow
  },
  {
    label: 'TVC',
    value: vodType.tvc
  }
]
