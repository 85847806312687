import { DeleteOutlined } from '@ant-design/icons'
import { Button, Card, Col, Row, Typography } from 'antd'
import Meta from 'antd/lib/card/Meta'
import { VodStatus } from './VodStatus'
import { vodStatus } from './constant'
import { getDuration } from 'src/helpers/time'
import prettyBytes from 'pretty-bytes'
import { PreviewVod } from './PreviewVod'

export const VodCard = (props) => {
  const { data, handleClickDelete, handleClickVod } = props

  return (
    <Card
      title={
        <Typography.Title
          level={5}
          onClick={() => handleClickVod(data)}
          style={{ cursor: 'pointer' }}
        >
          {data?.name}
        </Typography.Title>
      }
      extra={<VodStatus status={data.status || vodStatus.completed} />}
      headStyle={{ padding: '0 10px 0 10px' }}
    >
      <Row gutter={[24, 24]}>
        <PreviewVod id={data?.id} vodSelected={data} />
      </Row>
      <Row gutter={[24, 24]} style={{ paddingTop: '15px' }}>
        <Col span={12}>
          <Meta
            title={getDuration(data?.duration || 0) || 'N/A'}
            description={`${prettyBytes(data?.fileSize)}` || 'N/A'}
          />
        </Col>
        <Col span={12}>
          <Button danger onClick={() => handleClickDelete(data)}>
            <DeleteOutlined color="red" /> Delete
          </Button>
        </Col>
      </Row>
    </Card>
  )
}
