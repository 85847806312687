import { useState, useEffect } from 'react'

import {
  Row,
  Col,
  Breadcrumb,
  Button,
  Input,
  Drawer,
  Typography,
} from 'antd'

import { PoweroffOutlined, SearchOutlined } from '@ant-design/icons'

import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { Icon } from '../icons/Index'
import { breadcrumbName } from './constants'
import { logout } from 'src/services/api'

const ButtonContainer = styled.div`
  .ant-btn-primary {
    background-color: #1890ff;
  }
  .ant-btn-success {
    background-color: #52c41a;
  }
  .ant-btn-yellow {
    background-color: #fadb14;
  }
  .ant-btn-black {
    background-color: #262626;
    color: #fff;
    border: 0px;
    border-radius: 5px;
  }
  .ant-switch-active {
    background-color: #1890ff;
  }
`

const sidebarColorOptions = [
  {
    type: 'primary',
    color: '#1890ff',
  },
  {
    type: 'success',
    color: '#52c41a',
  },
  {
    type: 'danger',
    color: '#d9363e',
  },
  {
    type: 'yellow',
    color: '#fadb14',
  },
  {
    type: 'black',
    color: '#111',
  },
]

const sidebarTypeOption = [
  {
    type: 'transparent',
    name: 'transparent',
    color: 'transparent',
  },
  {
    type: 'white',
    name: 'white',
    color: '#fff',
  },
]

function Header({
  placement,
  name,
  handleSidebarColor,
  handleSidebarType,
}) {
  const { Title, Text } = Typography

  const [visible, setVisible] = useState(false)
  const [sidebarType, setSidebarType] = useState('transparent')

  useEffect(() => window.scrollTo(0, 0))

  const showDrawer = () => setVisible(true)
  const hideDrawer = () => setVisible(false)

  return (
    <>
      <Row gutter={[24, 0]}>
        <Col span={24} md={6}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <NavLink to="/">Pages</NavLink>
            </Breadcrumb.Item>
            <Breadcrumb.Item style={{ textTransform: 'capitalize' }}>
              {breadcrumbName[name.replace('/', '')]}
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col span={24} md={18} className="header-control">
          
          <Button type="link" onClick={showDrawer}>
            <Icon name="setting" />
          </Button>
          <Drawer
            className="settings-drawer"
            mask={true}
            width={360}
            onClose={hideDrawer}
            placement={placement}
            open={visible}
          >
            <div layout="vertical">
              <div className="header-top">
                <Title level={4}>
                  Configuration
                  <Text className="subtitle">See our dashboard options.</Text>
                </Title>
              </div>
              <div className="sidebar-color">
                <Title level={5}>Sidebar Color</Title>
                <div className="theme-color mb-2">
                  <ButtonContainer>
                    {sidebarColorOptions.map((option) => (
                      <Button
                        key={option.color}
                        type={option.type}
                        onClick={() => handleSidebarColor(option.color)}
                      >
                        1
                      </Button>
                    ))}
                  </ButtonContainer>
                </div>
                <div className="sidebarnav-color mb-2">
                  <Title level={5}>Sidebar Type</Title>
                  <Text>Choose between 2 different sidebar types.</Text>
                  <ButtonContainer className="trans">
                    {sidebarTypeOption.map((option) => (
                      <Button
                        key={option.name}
                        type={sidebarType === option.type ? 'primary' : 'white'}
                        onClick={() => {
                          handleSidebarType(option.color)
                          setSidebarType(option.type)
                        }}
                      >
                        {option.name}
                      </Button>
                    ))}
                  </ButtonContainer>
                </div>
              </div>
              <div className="sidebar-color">
                <Button
                  type="primary"
                  danger
                  icon={<PoweroffOutlined />}
                  style={{ width: '100%' }}
                  onClick={logout}
                >
                  Sign out
                </Button>
              </div>
            </div>
          </Drawer>
          <Input
            className="header-search"
            placeholder="Type here..."
            prefix={<SearchOutlined />}
          />
        </Col>
      </Row>
    </>
  )
}

export default Header
