export const WatermarkDisplayType = {
  Always: 1,
  Scenario: 2,
}

export const WatermarkDisplayTypeOptions = [
  {
    label: 'Always',
    value: WatermarkDisplayType.Always,
  },
  {
    label: 'Scenario',
    value: WatermarkDisplayType.Scenario,
  },
]

export const WatermarkDisplayTypeMap = {
  [WatermarkDisplayType.Always]: 'Always',
  [WatermarkDisplayType.Scenario]: 'Scenario',
}
