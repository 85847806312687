import dayjs from 'dayjs'

export const formatProgramToEvents = (programs, channel) => {
  return programs?.map((program) => {
    return {
      ...program,
      programs: programs,
      channelId: channel?.id,
      description: program?.description,
      title: program?.name,
      since: dayjs(program?.from).format('YYYY-MM-DD[T]HH:mm:ss'),
      till: dayjs(program?.to).format('YYYY-MM-DD[T]HH:mm:ss'),
      channelUuid: '16fdfefe-e466-4090-bc1a-57c43937f826',
      image:
        program?.vod?.thumbnail ||
        'https://upload.wikimedia.org/wikipedia/commons/c/c3/Circle-icons-camera.svg',
    }
  })
}

export const splitTime = (time) => {
  return time.format('HH:mm:ss').split(':')
}

export const splitTimeFromString = (time) => {
  return time.split(':')
}

export const getProgramEndTimeByStartTimeAndDuration = (
  startTime,
  duration = 60,
  unit = 'second',
) => {
  return startTime.add(duration, unit)
}