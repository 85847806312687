import { InboxOutlined } from '@ant-design/icons'
import { message, Upload } from 'antd'
import React, { useState } from 'react'
const { Dragger } = Upload

export const VodDragger = ({ setFileUpload }) => {
  const [fileList, setFileList] = useState([])

  const props = {
    name: 'file',
    multiple: false,
    beforeUpload: (file) => {
      if (file.type !== 'video/mp4') {
        setFileList([])
        message.error('You can only upload mp4 file!')
        return false
      }
      setFileList([file])
      setFileUpload(file)
      return false
    },
    onRemove: () => {
      setFileList([])
      setFileUpload(null)
    },
  }
  return (
    <Dragger {...props} fileList={fileList}>
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">
        Nhấp hoặc kéo tệp vào khu vực này để tải lên
      </p>
      <p className="ant-upload-hint">Hỗ trợ tải lên một lần hoặc hàng loạt</p>
    </Dragger>
  )
}
