import { call, put, takeLatest } from 'redux-saga/effects'
import { api } from 'src/services/api'
import { API_URL } from 'src/services/api/constant'
import {
  createVodFailed,
  deleteVodFailed,
  deleteVodSuccess,
  getListVodFailed,
  getListVodSuccess,
} from 'src/store/slice/vod'
import {
  CREATE_VOD_START,
  DELETE_VOD_START,
  GET_LIST_VOD_START,
  UPDATE_VOD_START,
} from 'src/store/slice/vod/type'
import addNotification, { NOTIFICATION_TYPE } from 'src/utils/toast'

const apiGetListVod = (params) => {
  return api.get(API_URL.VOD.LIST, params)
}

function* doGetListVod({ payload }) {
  try {
    const response = yield call(apiGetListVod, payload)
    if (!response?.data && payload.onFailed) {
      yield payload.onFailed()
    }
    if (response?.statusCode === 200 && response?.data) {
      yield put(getListVodSuccess(response?.data))
      if (payload.onSuccess) {
        yield payload.onSuccess()
      }
    }
  } catch (error) {
    yield put(getListVodFailed(error))
  }
}

const apiDeleteVod = (params) => {
  return api.delete(API_URL.VOD.DELETE.replace(':id', params?.id))
}

function* doDeleteVod({ payload }) {
  try {
    const response = yield call(apiDeleteVod, payload)
    if (!response?.data && payload.onFailed) {
      addNotification('Xoá vod thất bại', NOTIFICATION_TYPE.SUCCESS)
      yield payload.onFailed()
    }
    if (response.statusCode === 200) {
      addNotification(response?.message || 'Xoá vod thành công', NOTIFICATION_TYPE.SUCCESS)
      yield put(deleteVodSuccess(response?.data))
      if (payload.onSuccess) {
        yield payload.onSuccess()
      }
    }
  } catch (error) {
    addNotification('Xoá vod thất bại', NOTIFICATION_TYPE.SUCCESS)
    yield put(deleteVodFailed(error))
  }
}

const apiCreateVod = (params) => {
  return api.postMultiplePart(API_URL.VOD.CREATE, params)
}

function* doCreateVod({ payload }) {
  try {
    const response = yield call(apiCreateVod, payload?.payload)
    if (response.statusCode !== 200) {
      addNotification(response?.message || 'Tạo vod thất bại', NOTIFICATION_TYPE.SUCCESS)
      if (payload.onFailed) yield payload.onFailed()
    }
    if (response.statusCode === 200) {
      addNotification('Tạo vod thành công', NOTIFICATION_TYPE.SUCCESS)
      // yield put(createVodSuccess(response?.data))
      if (payload.onSuccess) {
        yield payload.onSuccess()
      }
    }
  } catch (error) {
    addNotification('Tạo vod thất bại', NOTIFICATION_TYPE.SUCCESS)
    yield put(createVodFailed(error))
  }
}

const apiUpdateVod = (params) => {
  return api.putMultiplePart(API_URL.VOD.UPDATE, params)
}

function* doUpdateVod({ payload }) {
  try {
    const response = yield call(apiUpdateVod, payload?.payload)
    if (response.statusCode !== 200) {
      addNotification('Cập nhật vod thất bại', NOTIFICATION_TYPE.SUCCESS)
      if (payload.onFailed) yield payload.onFailed()
    }
    if (response.statusCode === 200) {
      addNotification('Cập nhật vod thành công', NOTIFICATION_TYPE.SUCCESS)
      // yield put(createVodSuccess(response?.data))
      if (payload.onSuccess) {
        yield payload.onSuccess()
      }
    }
  } catch (error) {
    addNotification('Cập nhật vod thất bại', NOTIFICATION_TYPE.SUCCESS)
    yield put(createVodFailed(error))
  }
}

export function* watchDoGetListVod() {
  yield takeLatest(GET_LIST_VOD_START, doGetListVod)
}

export function* watchDoDeleteVod() {
  yield takeLatest(DELETE_VOD_START, doDeleteVod)
}

export function* watchDoCreateVod() {
  yield takeLatest(CREATE_VOD_START, doCreateVod)
}

export function* watchDoUpdateVod() {
  yield takeLatest(UPDATE_VOD_START, doUpdateVod)
}
