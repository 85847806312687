export const SLICE_CHANNEL = "SLICE_CHANNEL";
export const GET_LIST_CHANNEL = `${SLICE_CHANNEL}/getListChannel`
export const GET_LIST_CHANNEL_SUCCESS = `${SLICE_CHANNEL}/getListChannelSuccess`
export const GET_LIST_CHANNEL_FAILED = `${SLICE_CHANNEL}/getListChannelError`

export const CREATE_CHANNEL = `${SLICE_CHANNEL}/createChannel`
export const CREATE_CHANNEL_SUCCESS = `${SLICE_CHANNEL}/createChannelSuccess`
export const CREATE_CHANNEL_FAILED = `${SLICE_CHANNEL}/createChannelError`

export const UPDATE_CHANNEL = `${SLICE_CHANNEL}/updateChannel`
export const UPDATE_CHANNEL_SUCCESS = `${SLICE_CHANNEL}/updateChannelSuccess`
export const UPDATE_CHANNEL_FAILED = `${SLICE_CHANNEL}/updateChannelError`

export const DELETE_CHANNEL = `${SLICE_CHANNEL}/deleteChannel`
export const DELETE_CHANNEL_SUCCESS = `${SLICE_CHANNEL}/deleteChannelSuccess`
export const DELETE_CHANNEL_FAILED = `${SLICE_CHANNEL}/deleteChannelError`

export const DETAIL_CHANNEL = `${SLICE_CHANNEL}/getDetailChannel`
export const DETAIL_CHANNEL_SUCCESS = `${SLICE_CHANNEL}/getDetailChannelSuccess`
export const DETAIL_CHANNEL_FAILED = `${SLICE_CHANNEL}/getDetailChannelError`

export const DETAIL_CHANNEL_PROGRAM = `${SLICE_CHANNEL}/getDetailChannelProgram`
export const DETAIL_CHANNEL_PROGRAM_SUCCESS = `${SLICE_CHANNEL}/getDetailChannelProgramSuccess`
export const DETAIL_CHANNEL_PROGRAM_FAILED = `${SLICE_CHANNEL}/getDetailChannelProgramError`

export const UPDATE_CHANNEL_PROGRAM = `${SLICE_CHANNEL}/updateChannelProgram`
export const UPDATE_CHANNEL_PROGRAM_SUCCESS = `${SLICE_CHANNEL}/updateChannelProgramSuccess`
export const UPDATE_CHANNEL_PROGRAM_FAILED = `${SLICE_CHANNEL}/updateChannelProgramError`

export const DELETE_CHANNEL_PROGRAM = `${SLICE_CHANNEL}/deleteChannelProgram`
export const DELETE_CHANNEL_PROGRAM_SUCCESS = `${SLICE_CHANNEL}/deleteChannelProgramSuccess`
export const DELETE_CHANNEL_PROGRAM_FAILED = `${SLICE_CHANNEL}/deleteChannelProgramError`

export const ADD_CHANNEL_PROGRAM = `${SLICE_CHANNEL}/addChannelProgram`
export const ADD_CHANNEL_PROGRAM_SUCCESS = `${SLICE_CHANNEL}/addChannelProgramSuccess`
export const ADD_CHANNEL_PROGRAM_FAILED = `${SLICE_CHANNEL}/addChannelProgramError`