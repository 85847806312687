import { Button, Col, Drawer, Input, Row, Space, Form } from 'antd'
import { VodDragger } from './VodDragger'
import TagInput from 'antd-tag-input'

export const EditVodDrawer = (props) => {
  const { onClose, onSubmit, vodSelected, open } = props

  const [form] = Form.useForm()
  return (
    <Drawer
      title={'Cập nhật Vod'}
      width={720}
      onClose={onClose}
      open={open}
      styles={{ body: { paddingBottom: 80 } }}
      extra={
        <Space>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            onClick={() => form.submit()}
            type="primary"
            htmlType="submit"
          >
            Save
          </Button>
        </Space>
      }
    >
      <Form
        layout="vertical"
        onFinish={onSubmit}
        form={form}
        initialValues={{ ...vodSelected, tags: vodSelected?.tags?.split(',') }}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="name"
              label="Tên vod"
              rules={[{ required: true, message: 'Tên vod không được rỗng' }]}
            >
              <Input placeholder="Nhập tên vod" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="url" label="Vod url">
              <Input style={{ width: '100%' }} placeholder="Nhập url" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item name="description" label="Mô tả">
              <Input.TextArea rows={4} placeholder="Nhập mô tả vod" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item name="tags" label="Tags">
              <TagInput rows={4} placeholder="Nhập tags" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item name="file" label="Files">
              <VodDragger setFileUpload={props.setFileUpload} setFieldsValue />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Drawer>
  )
}
