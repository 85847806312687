export const SLICE_VOD = "SLICE_VOD";

export const GET_LIST_VOD_START = `${SLICE_VOD}/getListVod`;
export const GET_LIST_VOD_SUCCESS = `${SLICE_VOD}/getListVodSuccess`;
export const GET_LIST_VOD_FAILED = `${SLICE_VOD}/getListVodFailed`;

export const CREATE_VOD_START = `${SLICE_VOD}/createVod`
export const CREATE_VOD_SUCCESS = `${SLICE_VOD}/createVodSuccess`
export const CREATE_VOD_FAILED = `${SLICE_VOD}/createVodFailed`

export const UPDATE_VOD_START = `${SLICE_VOD}/updateVod`;
export const UPDATE_VOD_SUCCESS = `${SLICE_VOD}/updateVodSuccess`;
export const UPDATE_VOD_FAILED = `${SLICE_VOD}/updateVodFailed`;

export const DETAIL_VOD_START = `${SLICE_VOD}/detailVod`;
export const DETAIL_VOD_SUCCESS = `${SLICE_VOD}/detailVodSuccess`;
export const DETAIL_VOD_FAILED = `${SLICE_VOD}/detailVodFailed`;

export const DELETE_VOD_START = `${SLICE_VOD}/deleteVod`;
export const DELETE_VOD_SUCCESS = `${SLICE_VOD}/deleteVodSuccess`;
export const DELETE_VOD_FAILED = `${SLICE_VOD}/deleteVodFailed`;