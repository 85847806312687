import { call, put, takeLatest } from 'redux-saga/effects'
import {
  createProgramError,
  deleteProgramError,
  getDetailProgramError,
  getDetailProgramSuccess,
  getListProgramError,
  getListProgramSuccess,
} from 'src/store/slice/program'
import {
  ADD_CHANNEL_PROGRAM,
  CREATE_PROGRAM,
  DELETE_PROGRAM,
  DETAIL_PROGRAM,
  GET_LIST_PROGRAM,
} from 'src/store/slice/program/type'
import { api } from 'src/services/api'
import { API_URL } from 'src/services/api/constant'
import addNotification, { NOTIFICATION_TYPE } from 'src/utils/toast'

const apiGetListProgram = (params) => {
  return api.get(API_URL.PROGRAM.LIST, params)
}

function* doGetListProgram({ payload }) {
  try {
    const response = yield call(apiGetListProgram, payload)
    if (!response?.data) {
      yield put(getListProgramError())
    }
    yield put(getListProgramSuccess(response?.data || []))
    if (payload.onSuccess) {
      yield payload.onSuccess()
    }
  } catch (error) {
    yield put(getListProgramError(error))
  }
}

const apiCreateProgram = (params) => {
  return api.post(API_URL.PROGRAM.CREATE, params)
}

function* doCreateProgram({ payload }) {
  try {
    const response = yield call(apiCreateProgram, payload.data)
    if (response?.statusCode === 200) {
      addNotification('Tạo program thành công', NOTIFICATION_TYPE.SUCCESS)
      if (payload.onSuccess) {
        yield payload.onSuccess()
      }
    }
    if (response?.statusCode !== 200) {
      addNotification(response?.message || 'Có lỗi xảy ra vui lòng thử lại', NOTIFICATION_TYPE.ERROR)
      yield put(createProgramError())
    }
  } catch (error) {
    yield put(createProgramError(error))
  }
}

const apiDeleteProgram = (params) => {
  return api.delete(API_URL.PROGRAM.DELETE.replace(':id', params?.id))
}

function* doDeleteProgram({ payload }) {
  try {
    const response = yield call(apiDeleteProgram, payload)
    if (response?.statusCode === 200) {
      addNotification('Xoá program thành công', NOTIFICATION_TYPE.SUCCESS)
      if (payload.onSuccess) {
        yield payload.onSuccess()
      }
    }
    if (response?.statusCode !== 200) {
      addNotification(response?.message || 'Có lỗi xảy ra vui lòng thử lại', NOTIFICATION_TYPE.ERROR)
      yield put(deleteProgramError())
    }
  } catch (error) {
    yield put(deleteProgramError(error))
  }
}


const apiGetDetailProgram = (params) => {
  return api.get(API_URL.PROGRAM.DETAIL.replace(':id', params?.id))
}

function* doGetDetailProgram({ payload }) {
  try {
    const response = yield call(apiGetDetailProgram, payload)
    if (response?.statusCode === 200) {
      yield put(getDetailProgramSuccess(response?.data))
      if (payload.onSuccess) {
        yield payload.onSuccess(response)
      }
    }
    if (response?.statusCode !== 200) {
      addNotification('Có lỗi xảy ra vui lòng thử lại', NOTIFICATION_TYPE.ERROR)
      yield put(getDetailProgramError())
    }
  } catch (error) {
    yield put(getDetailProgramError(error))
  }
}


const apiAddChannelProgram = (params) => {
  return api.put(API_URL.PROGRAM.ADD_CHANNEL, params)
}

function* doAddChannelProgram({ payload }) {
  try {
    const response = yield call(apiAddChannelProgram, payload.data)
    if (response?.statusCode === 200) {
      addNotification('Tạo program thành công', NOTIFICATION_TYPE.SUCCESS)
      if (payload.onSuccess) {
        yield payload.onSuccess()
      }
    }
    if (response?.statusCode !== 200) {
      addNotification(response?.message || 'Có lỗi xảy ra vui lòng thử lại', NOTIFICATION_TYPE.ERROR)
      yield put(createProgramError())
    }
  } catch (error) {
    yield put(createProgramError(error))
  }
}

export function* watchDoGetListProgram() {
  yield takeLatest(GET_LIST_PROGRAM, doGetListProgram)
}

export function* watchDoCreateProgram() {
  yield takeLatest(CREATE_PROGRAM, doCreateProgram)
}

export function* watchDoDeleteProgram() {
  yield takeLatest(DELETE_PROGRAM, doDeleteProgram)
}

export function* watchDoGetDetailProgram() {
  yield takeLatest(DETAIL_PROGRAM, doGetDetailProgram)
}

export function* watchDoAddChannelProgram() {
  yield takeLatest(ADD_CHANNEL_PROGRAM, doAddChannelProgram)
}