// import {
//   ProgramBox,
//   ProgramContent,
//   ProgramFlex,
//   ProgramStack,
//   ProgramTitle,
//   ProgramText,
//   useProgram,
// } from '@nessprim/planby-pro'
// import { PreviewVod } from 'src/components/vod/PreviewVod'

// export const ProgramItem = ({ program, ...rest }) => {
//   const { styles, formatTime, set12HoursTimeFormat, isLive } = useProgram({
//     program,
//     ...rest,
//   })
//   const { data } = program
//   const { title, since, till, vodId } = data

//   const sinceTime = formatTime(since, set12HoursTimeFormat()).toLowerCase()
//   const tillTime = formatTime(till, set12HoursTimeFormat()).toLowerCase()
//   const handleClickEvent = () => {
//     // console.log('handleClickEvent:: ', data )
//   }
//   return (
//     <ProgramBox width={styles.width} style={styles.position}>
//       <ProgramContent width={styles.width} isLive={isLive}>
//         <ProgramFlex>
//           {/* {isLive && isMinWidth && <ProgramImage src={image} alt="Preview" />} */}
//           <PreviewVod width={50} id={vodId} wrapperStyle={{marginRight: '10px'}} />
//           <ProgramStack onClick={handleClickEvent}>
//             <ProgramTitle>{title}</ProgramTitle>
//             <ProgramText>
//               {sinceTime} - {tillTime}
//             </ProgramText>
//           </ProgramStack>
//         </ProgramFlex>
//       </ProgramContent>
//     </ProgramBox>
//   )
// }

import {
  ProgramBox,
  ProgramContent,
  ProgramFlex,
  ProgramStack,
  ProgramTitle,
  ProgramText,
  useProgram,
  ProgramResizeHandle,
} from '@nessprim/planby-pro'
import { Tooltip } from 'antd'
import { PreviewVod } from 'src/components/vod/PreviewVod'

export const ProgramItem = ({ program, ...rest }) => {
  const {
    isLive,
    isMouseEvent,
    styles,
    resizeEvents,
    formatTime,
    getMouseEvents,
    getMouseEventTempTime,
  } = useProgram({
    program,
    ...rest,
  })

  const { data } = program
  const { vodId, title, since, till } = data

  const sinceTime = formatTime(since).toLowerCase()
  const tillTime = formatTime(till).toLowerCase()
  const dragTime = getMouseEventTempTime()
  const time = isMouseEvent
    ? [dragTime.since, dragTime.till]
    : [sinceTime, tillTime]
  return (
    <Tooltip
      placement="top"
      title={`${title} ~ ${time[0] || 'N/A'} - ${time[1] || 'N/A'}`}
    >
      <ProgramBox
        width={styles.width}
        style={{
          ...styles.position,
          borderStyle: 'solid',
          borderRadius: '8px'
        }}
        {...getMouseEvents()}
      >
        <ProgramContent width={styles.width} isLive={isLive}>
          <ProgramResizeHandle {...resizeEvents.eventsLeft} />
          <ProgramResizeHandle {...resizeEvents.eventsRight} />
          <ProgramFlex>
            <PreviewVod
              width={50}
              id={vodId}
              wrapperStyle={{ marginRight: '10px' }}
            />
            <ProgramStack>
              <ProgramTitle>{title}</ProgramTitle>
              <ProgramText>
                {isMouseEvent ? (
                  <>
                    {dragTime.since} - {dragTime.till}
                  </>
                ) : (
                  <>
                    {sinceTime} - {tillTime}
                  </>
                )}
              </ProgramText>
            </ProgramStack>
          </ProgramFlex>
        </ProgramContent>
      </ProgramBox>
    </Tooltip>
  )
}
