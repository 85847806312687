import { Card, Col, Descriptions, Row } from 'antd'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { getDetailChannelProgram } from 'src/store/slice/channel'
import {
  channelStatus,
  channelStatusMap,
} from 'src/components/channel/constant'
import { SliceProgram } from 'src/components/channel/SliceProgram'
import dayjs from 'dayjs'

export const DetailChannel = () => {
  const { id: channelId } = useParams()
  const dispatch = useDispatch()
  const { detail: channel } = useSelector((state) => state.channels)
  const [dateSelected, setDateSelected] = useState(dayjs())

  useEffect(() => {
    dispatch(
      getDetailChannelProgram({
        id: channelId,
        date: dateSelected.format('YYYY-MM-DD'),
      }),
    )
  }, [channelId, dateSelected])

  return (
    <div className="tabled">
      <Row gutter={[24, 0]}>
        <Col span={24} md={24} className="mb-24">
          <Card className="card-billing-info" bordered="false">
            <Col span={12} key={channelId}>
              <div className="col-info">
                <Descriptions title={channel?.name}>
                  <Descriptions.Item label="description" span={3}>
                    {channel?.description}
                  </Descriptions.Item>
                  <Descriptions.Item label="status" span={3}>
                    <span
                      style={{
                        color:
                          +channel?.status === channelStatus.offline
                            ? '#f20202'
                            : '#38a63a',
                      }}
                    >
                      {channelStatusMap[channel?.status]}
                    </span>
                  </Descriptions.Item>
                  <Descriptions.Item label="createdAt" span={3}>
                    {channel?.createdAt
                      ? moment(channel?.createdAt).format('DD-MM-YYYY HH:mm')
                      : 'N/A'}
                  </Descriptions.Item>
                  <Descriptions.Item label="updatedAt" span={3}>
                    {channel?.updatedAt
                      ? moment(channel?.updatedAt).format('DD-MM-YYYY HH:mm')
                      : 'N/A'}
                  </Descriptions.Item>
                </Descriptions>
              </div>
            </Col>
          </Card>
        </Col>
      </Row>
      <Row gutter={[24, 0]}>
        <Col span={24} md={24} className="mb-24">
          <Card
            style={{
              boxShadow: 'none',
              backgroundColor: '#fafafa',
              border: '1px solid #f5f5f5',
            }}
            bordered="false"
          >
            <SliceProgram
              channelId={channelId}
              setDateSelected={setDateSelected}
              dateSelected={dateSelected}
              channel={channel}
              isDetail
              programs={channel?.programs}
            />
          </Card>
        </Col>
      </Row>
    </div>
  )
}
// 1EED93CF-8834-417E-8A25-B27AAE440904
