import { Card, Col, Input, Row, Form, Button, Select } from 'antd'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import {
  getDetailChannelProgram,
  updateChannel,
  updateChannelProgram,
} from 'src/store/slice/channel'
import { channelStatusOptions } from 'src/components/channel/constant'
import { ChannelAddProgramPopup } from 'src/components/channel/ChannelAddProgramPopup'
import { SliceProgram } from 'src/components/channel/SliceProgram'
import dayjs from 'dayjs'
import { Program } from '../program/Index'
import {
  splitTime,
  splitTimeFromString,
} from 'src/components/channel/SliceProgram/helpers/format'
import { last, sortBy } from 'lodash'
import { RemoveLastProgramPopup } from 'src/components/channel/RemoveLastProgramPopUp'

export const EditChannel = () => {
  const { id: channelId } = useParams()
  const dispatch = useDispatch()
  const { detail: channel } = useSelector((state) => state.channels)
  const [openAddProgram, setOpenAddProgram] = useState(false)
  const [openRemoveProgram, setOpenRemoveProgram] = useState(false)
  const [initFormAddProgram, setInitFormAddProgram] = useState(null)
  const [programs, setPrograms] = useState([])
  const [lastProgram, setLastProgram] = useState(null)
  const [dateSelected, setDateSelected] = useState(dayjs())
  const [form] = Form.useForm()

  useEffect(() => {
    if (channelId) {
      fetchDetail()
    }
  }, [channelId, dateSelected])

  const fetchDetail = (date = dateSelected.format('YYYY-MM-DD')) => {
    dispatch(
      getDetailChannelProgram({
        id: channelId,
        date: date,
      }),
    )
  }

  useEffect(() => {
    if (form) {
      form.setFieldsValue({
        name: channel?.name,
        description: channel?.channel,
        status: channel?.status,
      })
    }
    setPrograms(channel?.programs)
  }, [channel])

  const onGridItemClick = (e) => {
    const [fromHour, fromMinute] = splitTimeFromString(e?.since)
    setInitFormAddProgram({
      program: null,
      date: dateSelected,
      startTime: dateSelected
        ?.add(7, 'hour')
        ?.set('hour', fromHour)
        .set('minute', fromMinute)
        .set('second', 0),
    })
    setOpenAddProgram(true)
  }

  const onEventDnDSuccess = (event) => {
    const data = {
      date: dayjs(event.since).format('YYYY-MM-DD'),
      channelId: +event?.channelId,
      channelProgramId: +event?.channelProgramId,
      from: dayjs(event.since).toISOString(),
      to: dayjs(event.till).toISOString(),
    }
    dispatch(
      updateChannelProgram({
        data: data,
        onSuccess: () => fetchDetail(dayjs(event.since).format('YYYY-MM-DD')),
        onFailed: () => fetchDetail(dayjs(event.since).format('YYYY-MM-DD')),
      }),
    )
  }

  const onSubmitUpdateChannelData = (values) => {
    const data = {
      id: +channelId,
      name: values?.name,
      description: values?.description,
      status: values?.status,
      date: dateSelected.format('YYYY-MM-DD'),
      programs: programs.map((program) => {
        const [fromHour, fromMinute, fromSecond] = splitTime(
          dayjs(program.from),
        )
        const [toHour, toMinute, toSecond] = splitTime(dayjs(program.to))
        return {
          ...program,
          programId: program.id,
          from: dateSelected
            .hour(fromHour)
            .minute(fromMinute)
            .second(fromSecond),
          to: dateSelected.hour(toHour).minute(toMinute).second(toSecond),
        }
      }),
    }
    dispatch(
      updateChannel({
        data: data,
        onSuccess: fetchDetail,
      }),
    )
  }

  const handleClickAddProgram = () => {
    const lastProgramByEndTime = last(sortBy(channel?.programs, 'to'))
    const [fromHour, fromMinute] = splitTimeFromString(
      dayjs(lastProgramByEndTime?.to).format('HH:mm'),
    )
    const startTime = dateSelected
      ?.subtract(7, 'hour')
      ?.set('hour', fromHour)
      .set('minute', +fromMinute + 1)
      .set('second', 0)

    setInitFormAddProgram({
      program: null,
      date: dateSelected,
      startTime,
    })
    setOpenAddProgram(true)
  }

  const handleClickRemoveLastProgram = () => {
    setLastProgram(last(sortBy(channel?.programs, 'to')))
    setOpenRemoveProgram(true)
  }

  return (
    <div className="tabled">
      <Row gutter={[24, 0]}>
        <Col span={24} md={24} className="mb-24">
          <Card className="card-billing-info" bordered="false">
            <Form
              onFinish={onSubmitUpdateChannelData}
              form={form}
              layout="vertical"
            >
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item label="name" name="name" required>
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="status" name="status" required>
                    <Select>
                      {channelStatusOptions.map((status) => (
                        <Select.Option value={status.value} key={status.value}>
                          {status.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="description" name="description">
                    <Input.TextArea rows={4} />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
      <Row gutter={[24, 0]}>
        <Col span={24} md={24} className="mb-24">
          <Card
            style={{
              boxShadow: 'none',
              backgroundColor: '#fafafa',
              border: '1px solid #f5f5f5',
            }}
            bordered="false"
          >
            <SliceProgram
              channelId={channelId}
              setDateSelected={setDateSelected}
              dateSelected={dateSelected}
              channel={channel}
              onClickAddProgram={handleClickAddProgram}
              onClickRemoveLastProgram={handleClickRemoveLastProgram}
              onGridItemClick={onGridItemClick}
              programs={programs}
              onEventDnDSuccess={onEventDnDSuccess}
            />
            <Row gutter={24} justify="end" style={{ marginTop: '24px' }}>
              <Col span={3}>
                <Button
                  style={{ width: '100%' }}
                  onClick={() => form.resetFields()}
                  danger
                >
                  Clear Data
                </Button>
              </Col>
              <Col span={3}>
                <Button
                  style={{ width: '100%' }}
                  onClick={() => form.submit()}
                  type="primary"
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Card>
          <Card
            style={{
              boxShadow: 'none',
              backgroundColor: '#fafafa',
              border: '1px solid #f5f5f5',
              marginTop: '24px',
            }}
            bordered="false"
          >
            <Program channelId={channelId} reloadChannel={fetchDetail} />
          </Card>
        </Col>
      </Row>
      <ChannelAddProgramPopup
        open={openAddProgram}
        handleClose={() => setOpenAddProgram(false)}
        dateSelected={dateSelected}
        channel={channel}
        init={initFormAddProgram}
      />
      <RemoveLastProgramPopup
        open={openRemoveProgram}
        handleClose={() => setOpenRemoveProgram(false)}
        lastProgram={lastProgram}
        dateSelected={dateSelected}
        channel={channel}
      />
    </div>
  )
}
