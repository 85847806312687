export const SLICE_PROGRAM = 'SLICE_PROGRAM'

export const GET_LIST_PROGRAM = `${SLICE_PROGRAM}/getListProgram`
export const GET_LIST_PROGRAM_SUCCESS = `${SLICE_PROGRAM}/getListProgramSuccess`
export const GET_LIST_PROGRAM_FAILED = `${SLICE_PROGRAM}/getListProgramError`

export const CREATE_PROGRAM = `${SLICE_PROGRAM}/createProgram`
export const CREATE_PROGRAM_SUCCESS = `${SLICE_PROGRAM}/createProgramSuccess`
export const CREATE_PROGRAM_FAILED = `${SLICE_PROGRAM}/createProgramError`

export const DELETE_PROGRAM = `${SLICE_PROGRAM}/deleteProgram`
export const DELETE_PROGRAM_SUCCESS = `${SLICE_PROGRAM}/deleteProgramSuccess`
export const DELETE_PROGRAM_FAILED = `${SLICE_PROGRAM}/deleteProgramError`

export const DETAIL_PROGRAM = `${SLICE_PROGRAM}/getDetailProgram`
export const DETAIL_PROGRAM_SUCCESS = `${SLICE_PROGRAM}/getDetailProgramSuccess`
export const DETAIL_PROGRAM_FAILED = `${SLICE_PROGRAM}/getDetailProgramError`

export const ADD_CHANNEL_PROGRAM = `${SLICE_PROGRAM}/addChannelProgram`
export const ADD_CHANNEL_SUCCESS = `${SLICE_PROGRAM}/addChannelProgramSuccess`
export const ADD_CHANNEL_FAILED = `${SLICE_PROGRAM}/addChannelProgramError`