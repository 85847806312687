import { PlayCircleOutlined } from '@ant-design/icons'
import { ChannelBox } from '@nessprim/planby-pro'

export const ChannelItem = ({ channel, onClick }) => {
  const { position } = channel
  return (
    <ChannelBox {...position}>
      <PlayCircleOutlined
        style={{ color: '#ff0060', fontSize: '50px' }}
        onClick={onClick}
      />
    </ChannelBox>
  )
}
