import { ReactComponent as Dashboard } from 'src/assets/icons/dashboard.svg'
import { ReactComponent as Tables } from 'src/assets/icons/tables.svg'
import { ReactComponent as Billing } from 'src/assets/icons/billing.svg'
import { ReactComponent as Profile } from 'src/assets/icons/profile.svg'
import { ReactComponent as RTL } from 'src/assets/icons/rtl.svg'
import { ReactComponent as SignIn } from 'src/assets/icons/signin.svg'
import { ReactComponent as SignUp } from 'src/assets/icons/signup.svg'
import { ReactComponent as Bell } from 'src/assets/icons/bell.svg'
import { ReactComponent as Wifi } from 'src/assets/icons/wifi.svg'
import { ReactComponent as Credit } from 'src/assets/icons/credit.svg'
import { ReactComponent as Clock } from 'src/assets/icons/clock.svg'
import { ReactComponent as Angle } from 'src/assets/icons/angle.svg'
import { ReactComponent as Pencil } from 'src/assets/icons/pencil.svg'
import { ReactComponent as Download } from 'src/assets/icons/download.svg'
import { ReactComponent as Delete } from 'src/assets/icons/delete.svg'
import { ReactComponent as Calendar } from 'src/assets/icons/calendar.svg'
import { ReactComponent as Minus } from 'src/assets/icons/minus.svg'
import { ReactComponent as Dollar } from 'src/assets/icons/dollar.svg'
import { ReactComponent as Heart } from 'src/assets/icons/heart.svg'
import { ReactComponent as Cart } from 'src/assets/icons/cart.svg'
import { ReactComponent as Setting } from 'src/assets/icons/setting.svg'
import { ReactComponent as Toggler } from 'src/assets/icons/toggler.svg'

const icons = {
  dashboard: <Dashboard />,
  table: <Tables />,
  billing: <Billing />,
  profile: <Profile />,
  rtl: <RTL />,
  signin: <SignIn />,
  signup: <SignUp />,
  bell: <Bell />,
  wifi: <Wifi />,
  credit: <Credit />,
  clock: <Clock />,
  angle: <Angle />,
  pencil: <Pencil />,
  download: <Download />,
  delete: <Delete />,
  calendar: <Calendar />,
  minus: <Minus />,
  dollar: <Dollar />,
  heart: <Heart />,
  cart: <Cart />,
  setting: <Setting />,
  toggler: <Toggler />
}

export const Icon = ({ name }) => {
  return icons[name]
}
