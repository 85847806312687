import { PlusOutlined } from '@ant-design/icons'
import {
  Button,
  Col,
  Drawer,
  Row,
  Space,
  Form,
  Select,
  Card,
  Image,
  Descriptions,
  Input,
} from 'antd'
import { debounce, isNil } from 'lodash'
import prettyBytes from 'pretty-bytes'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getDuration } from 'src/helpers/time'
import { getListVod } from 'src/store/slice/vod'
import { CreateWatermarkProgram } from './CreateWatermarkProgramVertical'

export const CreateProgramDrawer = (props) => {
  const { onClose, onSubmit, open } = props
  const [watermarks, setWatermarks] = useState([])
  const [watermarkImages, setWatermarkImages] = useState({})
  const dispatch = useDispatch()

  const {
    data: { items: vods },
  } = useSelector((state) => state.vods)

  useEffect(() => getVodList(), [])
  const [vodSelected, setVodSelected] = useState(null)

  const getVodList = (keyword) => {
    const payload = { page: 1, limit: 20 }
    if (!isNil(keyword)) payload.keyword = keyword
    dispatch(getListVod(payload))
  }

  const debounceDropDown = useCallback(
    debounce((e) => {
      getVodList(e)
    }, 1000),
    [],
  )

  const handleDeleteWatermark = (watermarkRemove) => {
    setWatermarks(
      watermarks.filter((watermark) => watermark.name === watermarkRemove.name),
    )
  }

  const onSelectVod = (id) => {
    setVodSelected(vods.find((vod) => vod.id === id))
  }

  const onClickAddWatermark = () => {
    const newWatermarks = [...watermarks].concat({
      name: `watermark ${watermarks.length + 1}`,
    })
    setWatermarks(newWatermarks)
  }

  const [form] = Form.useForm()
  return (
    <Drawer
      title={'Thêm mới Program'}
      width={820}
      onClose={() => {
        onClose()
        form.resetFields()
      }}
      open={open}
      styles={{
        body: {
          paddingBottom: 80,
        },
      }}
      extra={
        <Space>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            onClick={() => form.submit()}
            type="primary"
            htmlType="submit"
          >
            Save
          </Button>
        </Space>
      }
    >
      <Form layout="vertical" onFinish={onSubmit} form={form}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Name" name="name">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="vod" label="Asset">
              <Select
                onSearch={(e) => {
                  debounceDropDown(e)
                }}
                onSelect={(e) => onSelectVod(e)}
                allowClear
                showSearch
              >
                {vods?.map((vod) => (
                  <Select.Option value={vod.id} key={vod.id}>
                    {vod.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {vodSelected && (
            <Col span={24} style={{ marginBottom: '10px' }}>
              <Row gutter={24}>
                <Col span={24}>
                  <Card>
                    <Row gutter={24}>
                      <Col span={5}>
                        <Image
                          width={100}
                          src={
                            vodSelected?.thumbnail ||
                            'https://upload.wikimedia.org/wikipedia/commons/c/c3/Circle-icons-camera.svg'
                          }
                          alt={vodSelected?.name}
                        />
                      </Col>
                      <Col span={19}>
                        <Descriptions>
                          <Descriptions.Item label="Tên" span={3}>
                            {vodSelected?.name}
                          </Descriptions.Item>
                          <Descriptions.Item label="Mô tả" span={3}>
                            {vodSelected?.description}
                          </Descriptions.Item>
                          <Descriptions.Item label="Duration" span={3}>
                            {vodSelected?.duration
                              ? getDuration(vodSelected?.duration || 0)
                              : 'N/A'}
                          </Descriptions.Item>
                          <Descriptions.Item label="size" span={3}>
                            {vodSelected?.fileSize
                              ? `${prettyBytes(vodSelected?.fileSize)}`
                              : 'N/A'}
                          </Descriptions.Item>
                        </Descriptions>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Col>
          )}
          <Col span={24}>
            <Form.Item>
              <Button icon={<PlusOutlined />} onClick={onClickAddWatermark}>
                Thêm watermark
              </Button>
            </Form.Item>
          </Col>
          {watermarks.map((watermark, index) => (
            <Col span={12}>
              <CreateWatermarkProgram
                watermark={watermark}
                itemIndex={index}
                key={index}
                setWatermarkImages={setWatermarkImages}
                watermarkImages={watermarkImages}
                handleDeleteWatermark={handleDeleteWatermark}
              />
            </Col>
          ))}
        </Row>
      </Form>
    </Drawer>
  )
}
