import { Col, Drawer, Row, Descriptions, Tag, Divider, Button } from 'antd'
import VideoJS from '../common/Video'
import TextArea from 'antd/lib/input/TextArea'
import { useEffect, useState } from 'react'
import { FormOutlined } from '@ant-design/icons'

export const VodDetailDrawer = (props) => {
  const { onClose, vodSelected, onClickOpenEditDrawer, open } = props
  const [pauseVideo, setPauseVideo] = useState(false)
  const [source, setSource] = useState({ source: vodSelected?.url })
  useEffect(() => {
    setSource(getSource(vodSelected))
  }, [vodSelected])

  const getSource = (vodSelected) => {
    return vodSelected?.url
      ? { src: vodSelected?.url }
      : {
          src: `http://beta-studio.evgcloud.net/api/vods/${vodSelected?.id}/preview`,
          type: vodSelected?.mimeType || 'video/mp4',
        }
  }
  const items = (data) => [
    {
      key: '1',
      label: 'Tên Vod',
      render: data?.name,
      span: 3,
    },
    {
      key: '2',
      label: 'Url',
      render: data?.url,
      span: 3,
    },
    {
      key: '3',
      label: 'Tags',
      render: () => {
        return (
          data?.tags && (
            <>
              {data?.tags?.split(',').map((tag) => (
                <Tag color="#2db7f5">{tag}</Tag>
              ))}
            </>
          )
        )
      },
      span: 3,
    },
    {
      key: '4',
      label: 'Mô tả',
      render: () => <TextArea disabled={true}>{data?.description}</TextArea>,
      span: 3,
    },
  ]

  return (
    <Drawer
      title={'Chi tiết Vod'}
      width={720}
      onClose={() => {
        onClose()
        setPauseVideo(true)
      }}
      open={open}
      styles={{ body: { paddingBottom: 80 } }}
      extra={
        <Button
          type="primary"
          onClick={() => onClickOpenEditDrawer(vodSelected)}
        >
          <FormOutlined /> edit
        </Button>
      }
    >
      <Row gutter={16}>
        <Col span={24}>
          <Descriptions bordered layout="horizontal">
            {items(vodSelected)?.map((item) => (
              <Descriptions.Item
                key={item.key}
                label={item.label}
                span={item.span}
              >
                {typeof item.render === 'function'
                  ? item?.render()
                  : item.render}
              </Descriptions.Item>
            ))}
          </Descriptions>
        </Col>
      </Row>
      <Divider />
      <Row gutter={16}>
        <Col span={24}>
          <VideoJS
            options={{
              autoplay: false,
              controls: true,
              responsive: true,
              fluid: true,
              sources: [{ ...source }],
            }}
            onPause={pauseVideo}
          />
        </Col>
      </Row>
    </Drawer>
  )
}
