import { Col, Image, Row } from 'antd'
import VideoJS from '../common/Video'
import { useEffect, useState } from 'react'
import { api } from 'src/services/api'
import { API_URL } from 'src/services/api/constant'

export const PreviewVod = (props) => {
  const { id, vodSelected = null } = props
  const [vod, setVod] = useState(vodSelected)
  useEffect(() => {
    if (!vodSelected) {
      getVod(id)
    }
  }, [id, vodSelected])

  const getVod = async (id) => {
    const response = await api.get(API_URL.VOD.DETAIL.replace(':id', id))
    setVod(response?.data)
  }

  const getSource = (vodSelected) => {
    return vodSelected?.url
      ? { src: vodSelected?.url }
      : {
          src: `http://beta-studio.evgcloud.net/api/vods/${vodSelected?.id}/preview`,
          type: vodSelected?.mimeType || 'video/mp4',
        }
  }

  return (
    <Image
      {...props}
      src={
        vod?.thumbnail ||
        'https://upload.wikimedia.org/wikipedia/commons/c/c3/Circle-icons-camera.svg'
      }
      alt={vod?.id}
      preview={{
        destroyOnClose: true,
        toolbarRender: () => <></>,
        imageRender: () => (
          <Row gutter={24} style={{ width: '60%' }}>
            <Col span={24}>
              <VideoJS
                options={{
                  autoplay: false,
                  controls: true,
                  responsive: true,
                  fluid: true,
                  sources: [getSource(vod)],
                }}
              />
            </Col>
          </Row>
        ),
      }}
    />
  )
}
