export const channelStatus = {
  offline: 0,
  online: 1,
}

export const channelStatusMap = {
  [channelStatus.offline]: 'OFFLINE',
  [channelStatus.online]: 'ONLINE',
}

export const channelStatusOptions = [
  {
    value: 0,
    label: channelStatusMap[0],
  },
  {
    value: 1,
    label: channelStatusMap[1],
  }
]