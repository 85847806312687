import {
  Button,
  Col,
  Space,
  Table,
  Tooltip,
  Typography,
} from 'antd'
import { VodStatus } from './VodStatus'
import { CloseOutlined, FormOutlined } from '@ant-design/icons'
import prettyBytes from 'pretty-bytes'
import { PreviewVod } from './PreviewVod'

export const VodTableView = (props) => {
  const {
    vods,
    handleClickDelete,
    handleClickEdit,
    handleClickVod,
    pagination,
  } = props
  const duration = (minutes) => {
    const date = new Date(0)
    date.setSeconds(minutes)
    return date.toISOString().substring(11, 19)
  }

  const customVodTableView = vods.map((vod, index) => {
    return {
      id: index + 1,
      name: (
        <Typography.Title
          level={5}
          onClick={() => handleClickVod(vod)}
          style={{ cursor: 'pointer' }}
        >
          {vod?.name}
        </Typography.Title>
      ),
      thumbnail: <PreviewVod id={vod?.id} vodSelected={vod} width={100} />,
      status: <VodStatus status={vod.status || 'completed'} />,
      duration: duration(vod?.duration || 0),
      size: `${prettyBytes(vod?.fileSize)}` || 'N/A',
      action: (
        <Space gap="middle">
          <Tooltip title="Edit">
            <Button type="primary" onClick={() => handleClickEdit(vod)}>
              <FormOutlined /> edit
            </Button>
          </Tooltip>
          <Tooltip title="Delete">
            <Button type="danger" onClick={() => handleClickDelete(vod)}>
              <CloseOutlined /> delete
            </Button>
          </Tooltip>
        </Space>
      ),
    }
  })

  const columns = [
    {
      title: '#',
      key: 'id',
      dataIndex: 'id',
    },
    {
      title: 'Tên Vod',
      key: 'name',
      dataIndex: 'name',
    },
    {
      title: 'Thumbnail',
      key: 'thumbnail',
      dataIndex: 'thumbnail',
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
    },
    {
      title: 'Duration',
      key: 'duration',
      dataIndex: 'duration',
    },
    {
      title: 'Size',
      key: 'size',
      dataIndex: 'size',
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: 'action',
    },
  ]

  return (
    <Col span={24} className="table-responsive">
      <Table
        columns={columns}
        dataSource={customVodTableView}
        pagination={pagination}
        className="ant-border-space"
        rowKey={'id'}
      />
    </Col>
  )
}
