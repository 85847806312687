import { Modal, Typography } from 'antd'
import dayjs from 'dayjs'
import { useDispatch } from 'react-redux'
import {
  deleteChannelProgram,
  getDetailChannelProgram,
} from 'src/store/slice/channel'

export const RemoveLastProgramPopup = (props) => {
  const { lastProgram, open, handleClose, channel, dateSelected } = props
  const dispatch = useDispatch()
  const onRemove = () => {
    dispatch(
      deleteChannelProgram({
        id: lastProgram?.channelProgramId,
        onSuccess: () => {
          dispatch(
            getDetailChannelProgram({
              id: channel?.id,
              date: dateSelected.format('YYYY-MM-DD'),
            }),
          )
          handleClose()
        },
        onFailed: () => handleClose(),
      }),
    )
  }
  return (
    <Modal
      title="Xoá Program"
      open={open}
      onOk={onRemove}
      onCancel={handleClose}
      okText="Delete"
      cancelText="Cancel"
    >
      Bạn có chắc chắn muốn xoá{' '}
      <Typography.Text level={4} strong={true}>
        {lastProgram?.name} Từ {dayjs(lastProgram?.from).format('HH:mm')} Đến{' '}
        {dayjs(lastProgram?.to).format('HH:mm')}
      </Typography.Text>{' '}
      không?
    </Modal>
  )
}
