import { createSlice } from '@reduxjs/toolkit'
import { SLICE_CHANNEL } from './type'

const channelInitialState = {
  data: { items: [], meta: {} },
  detail: {},
  isLoading: false,
  errors: null,
}

export const channelSlice = createSlice({
  name: SLICE_CHANNEL,
  initialState: channelInitialState,
  reducers: {
    getListChannel: (state) => {
      return {
        ...state,
        isLoading: true,
        errors: null,
      }
    },
    getListChannelSuccess: (_, { payload }) => {
      return {
        data: payload,
        isLoading: false,
        errors: null,
      }
    },
    getListChannelError: (state) => {
      return {
        ...state,
        isLoading: false,
        errors: 'Có lỗi xảy ra vui lòng thử lại',
      }
    },
    createChannel: (state) => {
      return {
        ...state,
        isLoading: true,
        errors: null,
      }
    },
    createChannelSuccess: (_, { payload }) => {
      return {
        data: payload,
        isLoading: false,
        errors: null,
      }
    },
    createChannelError: (state) => {
      return {
        ...state,
        isLoading: false,
        errors: 'Có lỗi xảy ra vui lòng thử lại',
      }
    },
    updateChannel: (state) => {
      return {
        ...state,
        isLoading: true,
        errors: null,
      }
    },
    updateChannelSuccess: (_, { payload }) => {
      return {
        data: payload,
        isLoading: false,
        errors: null,
      }
    },
    updateChannelError: (state) => {
      return {
        ...state,
        isLoading: false,
        errors: 'Có lỗi xảy ra vui lòng thử lại',
      }
    },
    deleteChannel: (state) => {
      return {
        ...state,
        isLoading: true,
        errors: null,
      }
    },
    deleteChannelSuccess: (_, { payload }) => {
      return {
        data: payload,
        isLoading: false,
        errors: null,
      }
    },
    deleteChannelError: (state) => {
      return {
        ...state,
        isLoading: false,
        errors: 'Có lỗi xảy ra vui lòng thử lại',
      }
    },
    getDetailChannel: (state) => {
      return {
        ...state,
        isLoading: true,
        errors: null,
      }
    },
    getDetailChannelSuccess: (_, { payload }) => {
      return {
        detail: payload,
        data: payload,
        isLoading: false,
        errors: null,
      }
    },
    getDetailChannelError: (state) => {
      return {
        ...state,
        isLoading: false,
        errors: 'Có lỗi xảy ra vui lòng thử lại',
      }
    },
    getDetailChannelProgram: (state) => {
      return {
        ...state,
        isLoading: true,
        errors: null,
      }
    },
    getDetailChannelProgramSuccess: (_, { payload }) => {
      return {
        detail: payload,
        data: payload,
        isLoading: false,
        errors: null,
      }
    },
    getDetailChannelProgramError: (state) => {
      return {
        ...state,
        isLoading: false,
        errors: 'Có lỗi xảy ra vui lòng thử lại',
      }
    },
    updateChannelProgram: (state) => {
      return {
        ...state,
        isLoading: true,
        errors: null,
      }
    },
    updateChannelProgramSuccess: (_, { payload }) => {
      return {
        data: payload,
        isLoading: false,
        errors: null,
      }
    },
    updateChannelProgramError: (state) => {
      return {
        ...state,
        isLoading: false,
        errors: 'Có lỗi xảy ra vui lòng thử lại',
      }
    },
    deleteChannelProgram: (_, { payload }) => {
      return {
        data: payload,
        isLoading: false,
        errors: null,
      }
    },
    deleteChannelProgramSuccess: (_, { payload }) => {
      return {
        data: payload,
        isLoading: false,
        errors: null,
      }
    },
    deleteChannelProgramError: (state) => {
      return {
        ...state,
        isLoading: false,
        errors: 'Có lỗi xảy ra vui lòng thử lại',
      }
    },
    addChannelProgram: (state) => {
      return {
        ...state,
        isLoading: true,
        errors: null,
      }
    },
    addChannelProgramSuccess: (_, { payload }) => {
      return {
        data: payload,
        isLoading: false,
        errors: null,
      }
    },
    addChannelProgramError: (state) => {
      return {
        ...state,
        isLoading: false,
        errors: 'Có lỗi xảy ra vui lòng thử lại',
      }
    },
  },
})

export const {
  getListChannel,
  getListChannelSuccess,
  getListChannelError,
  createChannel,
  createChannelSuccess,
  createChannelError,
  deleteChannel,
  deleteChannelError,
  deleteChannelSuccess,
  getDetailChannel,
  getDetailChannelSuccess,
  getDetailChannelError,
  updateChannel,
  updateChannelSuccess,
  updateChannelError,
  getDetailChannelProgram,
  getDetailChannelProgramSuccess,
  getDetailChannelProgramError,
  updateChannelProgram,
  updateChannelProgramSuccess,
  updateChannelProgramError,
  deleteChannelProgram,
  deleteChannelProgramSuccess,
  deleteChannelProgramError,
  addChannelProgram,
  addChannelProgramSuccess,
  addChannelProgramError,
} = channelSlice.actions
export default channelSlice.reducer
