import createSagaMiddleware from 'redux-saga'
import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import channelReducer from './slice/channel'
import authReducer from './slice/authentication'
import vodReducer from './slice/vod'
import programReducer from './slice/program'
import rootSaga from './sagas'

const sagaMiddleware = createSagaMiddleware()

const middleware = (getDefaultMiddleware) => {
  return getDefaultMiddleware({
    thunk: false,
    serializableCheck: false,
  }).prepend([sagaMiddleware])
}

const rootReducer = combineReducers({
  auth: authReducer,
  channels: channelReducer,
  vods: vodReducer,
  programs: programReducer,
})

const store = configureStore({
  reducer: rootReducer,
  middleware: middleware,
})

sagaMiddleware.run(rootSaga)

export default store
