import { Button, Col, Drawer, Input, Row, Space, Form, Select } from 'antd'
import { channelStatusOptions } from './constant'

export const ChannelCreateDrawer = (props) => {
  const { onClose, onSubmit, open } = props

  const [form] = Form.useForm()
  return (
    <Drawer
      title={'Thêm mới Channel'}
      width={720}
      onClose={() => {
        onClose()
        form.resetFields()
      }}
      open={open}
      styles={{
        body: {
          paddingBottom: 80,
        },
      }}
      extra={
        <Space>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            onClick={() => form.submit()}
            type="primary"
            htmlType="submit"
          >
            Save
          </Button>
        </Space>
      }
    >
      <Form layout="vertical" onFinish={onSubmit} form={form}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="name"
              label="Tên Channel"
              rules={[
                { required: true, message: 'Tên channel không được rỗng' },
              ]}
            >
              <Input placeholder="Nhập tên channel" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="status" label="Trạng thái">
              <Select>
                {channelStatusOptions.map((status) => (
                  <Select.Option value={status.value} key={status.value}>
                    {status.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item name="description" label="Mô tả">
              <Input.TextArea rows={4} placeholder="Nhập mô tả channel" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Drawer>
  )
}
