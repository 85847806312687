import { DeleteOutlined, UploadOutlined } from '@ant-design/icons'
import {
  Card,
  Switch,
  Form,
  Row,
  Col,
  InputNumber,
  Upload,
  Button,
  Select,
  Input,
} from 'antd'
import { WatermarkDisplayTypeOptions } from './constant'

export const CreateWatermarkProgram = (props) => {
  const {
    watermark,
    itemIndex,
    setWatermarkImages,
    watermarkImages,
    handleDeleteWatermark,
  } = props
  const watermarkImageName = `watermark${itemIndex}`
  const watermarkImage = watermarkImages[watermarkImageName]
  const uploadProps = {
    listType: 'picture',
    accept: 'image/*',
    multiple: false,
    maxCount: 1,
    defaultFileList: watermarkImage ? watermarkImage : [],
    beforeUpload: (file) => {
      if (!file.type.includes('image/')) {
        setWatermarkImages({ ...watermarkImages, [watermarkImageName]: [] })
        return false
      }
      setWatermarkImages({ ...watermarkImages, [watermarkImageName]: [file] })
      return false
    },
    onRemove: () => {
      setWatermarkImages({ ...watermarkImages, [watermarkImageName]: [] })
    },
  }

  return (
    <Card
      title={watermark.name}
      extra={
        <Button
          onClick={handleDeleteWatermark}
          icon={<DeleteOutlined />}
          danger
        >
          Remove
        </Button>
      }
      style={{ marginBottom: '10px' }}
    >
      <Form.Item>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label="Name"
              name={['watermarks', itemIndex, 'name']}
              initialValue={watermark.name}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Enable"
              name={['watermarks', itemIndex, 'status']}
              initialValue={true}
            >
              <Switch defaultChecked />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Delay(s)"
              name={['watermarks', itemIndex, 'delay']}
              initialValue={0}
              required
            >
              <InputNumber min={0} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Media scale"
              name={['watermarks', itemIndex, 'mediaScale']}
              initialValue={1}
              required
            >
              <InputNumber min={1} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="X" name={['watermarks', itemIndex, 'x']} required>
              <InputNumber min={0} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Y" name={['watermarks', itemIndex, 'y']} required>
              <InputNumber min={0} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Z-Index"
              name={['watermarks', itemIndex, 'zIndex']}
              initialValue={1}
            >
              <InputNumber min={1} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Display Type"
              name={['watermarks', itemIndex, 'displayType']}
              initialValue={1}
              required
            >
              <Select
                allowClear={true}
                options={WatermarkDisplayTypeOptions}
                placeholder="Display Type"
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Asset"
              name={['watermarks', itemIndex, 'asset']}
              required
            >
              <Upload {...uploadProps}>
                <Button icon={<UploadOutlined />}>Upload</Button>
              </Upload>
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>
    </Card>
  )
}
