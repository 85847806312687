import { Modal } from 'antd'
import VideoJS from '../common/Video'

export const ViewLiveChannel = (props) => {
  const { LinkLiveStream, open, handleClose } = props
  return (
    <Modal
      closable={false}
      open={open}
      destroyOnClose={true}
      onCancel={handleClose}
      cancelText="Close"
      width={1300}
      okButtonProps={{ style: { display: 'none' } }}
    >
      <VideoJS
        options={{
          autoplay: false,
          controls: true,
          responsive: true,
          fluid: true,
          sources: [{ src: LinkLiveStream }],
        }}
      />
    </Modal>
  )
}
