import { Col, Form, Modal, Row, Select, TimePicker } from 'antd'
import { debounce, isNil } from 'lodash'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addChannelProgram, getDetailChannelProgram } from 'src/store/slice/channel'
import { getListProgram } from 'src/store/slice/program'
import {
  getProgramEndTimeByStartTimeAndDuration, splitTime,
} from './SliceProgram/helpers/format'
import dayjs from 'dayjs'

export const ChannelAddProgramPopup = (props) => {
  const { open, handleClose, dateSelected, channel, init } = props
  const dispatch = useDispatch()
  const [form] = Form.useForm()

  useEffect(() => {
    getPrograms()
  }, [])

  useEffect(() => {
    if (form) {
      form.setFieldsValue({
        init, startTime: init?.startTime ? dayjs(init?.startTime, 'HH:mm:ss') : dayjs(),
      })
    }
  }, [init])

  const {
    data: { items: programs },
  } = useSelector((state) => state.programs)

  const getPrograms = (keyword) => {
    const payload = { page: 1, limit: 20 }
    if (!isNil(keyword)) payload.keyword = keyword
    dispatch(getListProgram(payload))
  }

  const debounceDropDown = useCallback(debounce((e) => getPrograms(e), 1000), [])

  const getProgramById = (programId) => {
    return programs.find((program) => +program.id === +programId)
  }

  const onSubmitAddProgram = (values) => {
    const { program: programId, startTime } = values
    const programData = getProgramById(programId)
    const endTime = getProgramEndTimeByStartTimeAndDuration(startTime, programData.vod.duration)
    const [fromHour, fromMinute, fromSecond] = splitTime(startTime)
    const [toHour, toMinute, toSecond] = splitTime(endTime)
    const data = {
      channelId: channel?.id,
      programId: programId,
      date: dateSelected.format('YYYY-MM-DD'),
      from: dateSelected.hour(fromHour).minute(fromMinute).second(fromSecond),
      to: dateSelected.hour(toHour).minute(toMinute).second(toSecond),
    }
    dispatch(addChannelProgram({
      data: data, onSuccess: () => {
        dispatch(getDetailChannelProgram({
          id: channel?.id, date: dateSelected.format('YYYY-MM-DD'),
        }))
        handleClose()
        form.resetFields()
      },
    }))
  }

  return (<Modal
    title="Thêm Program"
    open={open}
    onOk={() => form.submit()}
    onCancel={handleClose}
    okText="Save"
    cancelText="Cancel"
  >
    <Form onFinish={onSubmitAddProgram} form={form}>
      <Row gutter={24}>
        <Col span={24}>
          <Form.Item
            name="program"
            label="program"
            rules={[{ required: true, message: 'Chọn program' }]}
          >
            <Select
              onSearch={(e) => debounceDropDown(e)}
              allowClear
              showSearch
            >
              {programs?.map((program) => (<Select.Option value={program.id} key={program.id}>
                {program.name}
              </Select.Option>))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="date" label="Ngày" required>
            {dateSelected.format('DD-MM-YYYY')}
          </Form.Item>
        </Col>
        <Col span={16}>
          <Form.Item
            name="startTime"
            label="Thời gian bắt đầu"
            rules={[{ required: true, message: 'Chọn thời gian bắt đầu' }]}
          >
            <TimePicker allowClear />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  </Modal>)
}
