import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Layout, Drawer } from 'antd'
import Sidebar from 'src/components/layout/Sidebar'
import Header from 'src/components/layout/Header'
import Footer from 'src/components/layout/Footer'
import { logout } from 'src/services/api'

const { Header: AntHeader, Sider, Content } = Layout

function PrivateLayout({ children }) {
  const token = localStorage.getItem('token')
  if (!token) {
    return logout()
  }

  const [visible, setVisible] = useState(false)
  const [placement, setPlacement] = useState('right')
  const [sidebarColor, setSidebarColor] = useState('#1890ff')
  const [sidebarType, setSidebarType] = useState('transparent')

  const openDrawer = () => setVisible(!visible)
  const handleSidebarType = (type) => setSidebarType(type)
  const handleSidebarColor = (color) => setSidebarColor(color)

  let { pathname } = useLocation()
  pathname = pathname.replace('/', '')

  useEffect(() => {
    if (pathname === 'rtl') {
      setPlacement('left')
    } else {
      setPlacement('right')
    }
  }, [pathname])

  return (
    <Layout
      className={`layout-dashboard ${
        pathname === 'profile' ? 'layout-profile' : ''
      } ${pathname === 'rtl' ? 'layout-dashboard-rtl' : ''}`}
    >
      <Drawer
        title={false}
        placement={placement === 'right' ? 'left' : 'right'}
        closable={false}
        onClose={() => setVisible(false)}
        open={visible}
        key={placement === 'right' ? 'left' : 'right'}
        width={250}
        className={`drawer-sidebar ${
          pathname === 'rtl' ? 'drawer-sidebar-rtl' : ''
        } `}
      >
        <Layout
          className={`layout-dashboard ${
            pathname === 'rtl' ? 'layout-dashboard-rtl' : ''
          }`}
        >
          <Sider
            trigger={null}
            width={250}
            theme="light"
            className={`sider-primary ant-layout-sider-primary ${
              sidebarType === '#fff' ? 'active-route' : ''
            }`}
            style={{ background: sidebarType }}
          >
            <Sidebar color={sidebarColor} />
          </Sider>
        </Layout>
      </Drawer>
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        trigger={null}
        width={250}
        theme="light"
        className={`sider-primary ant-layout-sider-primary ${
          sidebarType === '#fff' ? 'active-route' : ''
        }`}
        style={{ background: 'sidebarType' }}
      >
        <Sidebar color={sidebarColor} />
      </Sider>
      <Layout style={{ marginLeft: '270px' }}>
        <AntHeader className="ant-header-fixed">
          <Header
            onPress={openDrawer}
            name={pathname}
            subName={pathname}
            handleSidebarColor={handleSidebarColor}
            handleSidebarType={handleSidebarType}
          />
        </AntHeader>
        <Content className="content-ant">{children}</Content>
        <Footer />
      </Layout>
    </Layout>
  )
}

export default PrivateLayout
