import { Epg, Layout, useEpg } from '@nessprim/planby-pro'
import { Timeline, ProgramItem, ChannelItem, GridCell } from './components'
import { Button, Col, DatePicker, Row, Slider, Space } from 'antd'
import {
  DeleteOutlined,
  PlayCircleOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons'
import { theme } from './helpers/theme'
import { API_URL } from 'src/services/api/constant'
import addNotification, { NOTIFICATION_TYPE } from 'src/utils/toast'
import { useEffect, useMemo, useState } from 'react'
import { api } from 'src/services/api'
import { ViewLiveChannel } from '../ViewLiveChannel'
import dayjs from 'dayjs'
import { SliceProgramMarks } from './constant'
import { formatProgramToEvents } from './helpers/format'

export const SliceProgram = (props) => {
  const {
    setDateSelected,
    dateSelected = dayjs(),
    onClickAddProgram,
    isDetail,
    onGridItemClick = () => {},
    channel,
    programs,
    onEventDnDSuccess = () => {},
    onClickRemoveLastProgram,
  } = props
  const [linkLiveStream, setLinkLiveStream] = useState(null)
  const [openLiveStream, setOpenLiveStream] = useState(null)
  const events = useMemo(
    () => formatProgramToEvents(programs, channel),
    [programs],
  )
  const [startTime, setStartTime] = useState(
    dateSelected
      ?.add(7, 'hour')
      ?.startOf('date')
      .format('YYYY-MM-DD[T]HH:mm:ss'),
  )
  const [endTime, setEndTime] = useState(
    dateSelected
      ?.add(1, 'day')
      ?.startOf('date')
      .format('YYYY-MM-DD[T]HH:mm:ss'),
  )

  useEffect(() => {
    if (dateSelected) {
      setStartTime(
        dateSelected?.startOf('date').format('YYYY-MM-DD[T]HH:mm:ss'),
      )
      setEndTime(
        dateSelected
          ?.set('hour', 24)
          .set('minute', 0)
          .set('second', 0)
          .format('YYYY-MM-DD[T]HH:mm:ss'),
      )
    }
  }, [dateSelected])

  const handleClickLiveStream = async () => {
    const response = await api.get(
      API_URL.CHANNEL.DETAIL_PROGRAM.replace(':id', channel?.id),
      { date: dateSelected.format('YYYY-MM-DD') },
    )
    if (response?.statusCode === 200) {
      setLinkLiveStream(response?.data?.livestream)
      setOpenLiveStream(true)
    }
    if (response?.statusCode !== 200) {
      addNotification('Có lỗi xảy ra vui lòng thử lại', NOTIFICATION_TYPE.ERROR)
      setLinkLiveStream(null)
      setOpenLiveStream(false)
    }
  }

  const { getEpgProps, getLayoutProps } = useEpg({
    channels: [{ uuid: '16fdfefe-e466-4090-bc1a-57c43937f826' }],
    epg: events || [],
    dayWidth: 6000,
    startDate: startTime, // YYYY-MM-DD[T]HH:mm:ss
    endDate: endTime, // YYYY-MM-DD[T]HH:mm:ss
    isSidebar: true,
    itemHeight: 90,
    isBaseTimeFormat: false,
    isResize: false,
    timelineDividers: 4,
    overlap: { enabled: false },
    grid: {
      enabled: true,
      hoverHighlight: true,
      onGridItemClick: (e) => onGridItemClick(e),
    },
    dnd: {
      enabled: !isDetail,
      mode: 'multi-rows',
      onDnDMouseUp: () => {
        return true
      },
      onDnDSuccess: (event) => onEventDnDSuccess(event),
    },
    theme,
  })

  const handleChangeTimeRange = ([from, to]) => {
    setStartTime(
      dateSelected
        ?.set('hour', from)
        .set('minute', 0)
        .set('second', 0)
        .format('YYYY-MM-DD[T]HH:mm:ss'),
    )
    setEndTime(
      dateSelected
        ?.set('hour', to)
        .set('minute', 0)
        .set('second', 0)
        .format('YYYY-MM-DD[T]HH:mm:ss'),
    )
  }

  return (
    <Row gutter={24}>
      <Col span={24}>
        <Space>
          <DatePicker
            onChange={(date) => setDateSelected(date)}
            defaultValue={dateSelected}
          />
          {onClickAddProgram && (
            <Button
              icon={<PlusCircleOutlined />}
              onClick={onClickAddProgram}
              type="primary"
            >
              add program
            </Button>
          )}
          {onClickRemoveLastProgram && (
            <Button
              icon={<DeleteOutlined />}
              onClick={onClickRemoveLastProgram}
              danger
            >
              remove program
            </Button>
          )}
          <Button icon={<PlayCircleOutlined />} onClick={handleClickLiveStream}>
            live stream
          </Button>
        </Space>
      </Col>
      <Col span={24}>
        <Slider
          marks={SliceProgramMarks}
          style={{ width: '100%' }}
          range
          defaultValue={[0, 24]}
          min={0}
          max={24}
          onChange={handleChangeTimeRange}
        />
      </Col>
      <Col span={24}>
        <Epg {...getEpgProps()}>
          <Layout
            {...getLayoutProps()}
            renderTimeline={(props) => <Timeline {...props} />}
            renderProgram={({ program, ...rest }) => {
              return (
                <ProgramItem
                  key={program.data.id}
                  program={program}
                  {...rest}
                />
              )
            }}
            renderChannel={({ channel }) => (
              <ChannelItem
                key={channel.id}
                onClick={handleClickLiveStream}
                channel={{
                  ...channel,
                  type: 'channel',
                  provider: 7427,
                  title: channel?.name,
                  uuid: '16fdfefe-e466-4090-bc1a-57c43937f826',
                }}
              />
            )}
            renderGridCell={({ id, ...rest }) => (
              <GridCell key={id} {...rest} isHoverHighlight={true} />
            )}
          />
        </Epg>
      </Col>
      <ViewLiveChannel
        open={openLiveStream}
        handleClose={() => {
          setLinkLiveStream(null)
          setOpenLiveStream(false)
        }}
        LinkLiveStream={linkLiveStream}
      />
    </Row>
  )
}
