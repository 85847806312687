import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Pagination,
  Radio,
  Row,
  Space,
  Typography,
} from 'antd'
import {
  CreditCardOutlined,
  SearchOutlined,
  TableOutlined,
  UploadOutlined,
} from '@ant-design/icons'
import { useEffect, useState } from 'react'
import { CreateVodDrawer } from 'src/components/vod/CreateVodDrawer'
import { VodDetailDrawer } from 'src/components/vod/DetailVodDrawer'
import { CardVodView } from 'src/components/vod/VodCardView'
import { EditVodDrawer } from 'src/components/vod/EditVodDrawer'
import { DeleteVodModal } from 'src/components/vod/DeleteVodPopup'
import { VodTableView } from 'src/components/vod/VodTableView'
import { useDispatch, useSelector } from 'react-redux'
import {
  createVod,
  deleteVod,
  getListVod,
  updateVod,
} from 'src/store/slice/vod'
import { isEmpty, isNil } from 'lodash'

export const Vod = () => {
  const {
    data: { items = [], meta },
  } = useSelector((state) => state.vods)

  const [page, setPage] = useState(meta?.page || 1)
  const [limit, setLimit] = useState(20)
  const [openModalDelete, setOpenModalDelete] = useState(false)
  const [vodSelected, setVodSelected] = useState(null)
  const [layout, setLayout] = useState('table')
  const [openCreateDrawer, setOpenCreateDrawer] = useState(false)
  const [openEditDrawer, setOpenEditDrawer] = useState(false)
  const [openDetailDrawer, setOpenDetailDrawer] = useState(false)
  const [fileUpload, setFileUpload] = useState(null)
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  useEffect(() => {
    fetchData(page, limit)
  }, [page, limit])

  const fetchData = (page, limit, filter) => {
    const params = { page: page, limit: limit }
    if (!isEmpty(filter)) {
      params.filter = JSON.stringify(filter)
    }
    dispatch(getListVod(params))
  }

  const handleClickDelete = (vod) => {
    setVodSelected(vod)
    setOpenModalDelete(true)
  }

  const handleClickVod = (vod) => {
    setVodSelected(vod)
    setOpenDetailDrawer(true)
  }

  const onSubmitDelete = () => {
    dispatch(
      deleteVod({
        id: vodSelected?.id,
        onSuccess: () => {
          fetchData(page, limit)
          setOpenModalDelete(false)
        },
        onFailed: () => setOpenModalDelete(false),
      }),
    )
  }

  const onSubmit = (values) => {
    const data = new FormData()
    data.append('name', values?.name)
    data.append('description', values?.name)
    values?.tags &&
      !isEmpty(values?.tags) &&
      data.append('tags', values?.tags?.join(','))
    data.append('type', values?.file ? 1 : 0)
    values?.url && data.append('url', values?.url)
    !isEmpty(fileUpload) &&
      !isNil(fileUpload) &&
      data.append('files', fileUpload)

    dispatch(
      createVod({
        payload: data,
        onSuccess: () => {
          fetchData(page, limit)
          setOpenCreateDrawer(false)
        },
      }),
    )
  }

  const onSubmitEdit = (values) => {
    const data = new FormData()
    data.append('id', vodSelected?.id)
    data.append('name', values?.name)
    data.append('description', values?.name)
    data.append('type', values?.file ? 1 : 0)
    values?.tags &&
      !isEmpty(values?.tags) &&
      data.append('tags', values?.tags?.join(','))
    values?.url && data.append('url', values?.url)
    !isEmpty(fileUpload) &&
      !isNil(fileUpload) &&
      data.append('files', fileUpload)

    dispatch(
      updateVod({
        payload: data,
        onSuccess: () => {
          fetchData(page, limit)
          setOpenEditDrawer(false)
        },
      }),
    )
  }

  const onChangeLayout = (e) => {
    setLayout(e)
  }

  const onClickUpload = () => {
    setOpenCreateDrawer(!openCreateDrawer)
  }

  const onClickOpenEditDrawer = (vod) => {
    setVodSelected(vod)
    setOpenDetailDrawer(false)
    setOpenEditDrawer(true)
  }

  const onSearch = (values) => {
    const filters = []
    for (const i in values) {
      if (values[i]) {
        filters.push({
          column: i,
          text: values[i],
        })
      }
    }
    fetchData(page, limit, filters)
  }

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col span={12}>
          <Typography.Title level={4}>Danh sách Vod</Typography.Title>
        </Col>
        <Col span={3} offset={9}>
          <Button
            type="primary"
            icon={<UploadOutlined />}
            onClick={onClickUpload}
          >
            Thêm mới Vod
          </Button>
        </Col>
      </Row>
      <Form layout="horizontal" onFinish={onSearch} form={form}>
        <Row gutter={[24, 24]} style={{ marginTop: '5px' }}>
          <Col span={4}>
            <Form.Item name="name" label="Tên vod">
              <Input />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="tags" label="Tags">
              <Input />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Button icon={<SearchOutlined />} onClick={() => form.submit()}>
              Search
            </Button>
          </Col>
          <Col span={3} offset={11}>
            <Radio.Group
              value={layout}
              onChange={(e) => onChangeLayout(e.target.value)}
            >
              <Radio.Button value="table">
                <Space>
                  <TableOutlined /> Bảng
                </Space>
              </Radio.Button>
              <Radio.Button value="card">
                <Space>
                  <CreditCardOutlined /> Thẻ
                </Space>
              </Radio.Button>
            </Radio.Group>
          </Col>
        </Row>
      </Form>
      <Row Row gutter={[24, 24]}>
        {layout === 'card' && (
          <CardVodView
            vods={items || []}
            handleClickDelete={handleClickDelete}
            handleClickVod={handleClickVod}
            setOpenDetailDrawer={() => setOpenDetailDrawer(true)}
          />
        )}
        {layout === 'table' && (
          <VodTableView
            vods={items || []}
            handleClickEdit={onClickOpenEditDrawer}
            handleClickDelete={handleClickDelete}
            handleClickVod={handleClickVod}
            pagination={{
              onChange: (page, pageSize) => {
                setPage(page)
                setLimit(pageSize)
              },
              hideOnSinglePage: true,
              pageSize: limit,
              showSizeChanger: true,
              pageSizeOptions: ['10', '20', '30'],
              total: meta?.total,
            }}
          />
        )}
      </Row>
      <Divider />
      {layout === 'card' && (
        <Row Row gutter={[24, 24]} className="footer">
          <Col span={8} offset={9}>
            <Pagination
              showQuickJumper={false}
              current={meta?.page}
              total={meta?.total}
              showSizeChanger={true}
              onChange={(page, pageSize) => {
                setPage(page)
                setLimit(pageSize)
              }}
              hideOnSinglePage={true}
              pageSizeOptions={['20', '50', '100']}
              pageSize={limit}
            />
          </Col>
        </Row>
      )}
      <CreateVodDrawer
        onClose={() => {
          setOpenCreateDrawer(false)
          setVodSelected(null)
        }}
        open={openCreateDrawer}
        onSubmit={onSubmit}
        setFileUpload={setFileUpload}
      />
      <EditVodDrawer
        onClose={() => {
          setOpenEditDrawer(false)
          setVodSelected(null)
        }}
        open={openEditDrawer}
        onSubmit={onSubmitEdit}
        vodSelected={vodSelected}
        setFileUpload={setFileUpload}
      />
      <VodDetailDrawer
        onClose={() => {
          setOpenDetailDrawer(false)
          setVodSelected(null)
        }}
        open={openDetailDrawer}
        vodSelected={vodSelected}
        onClickOpenEditDrawer={onClickOpenEditDrawer}
      />
      <DeleteVodModal
        open={openModalDelete}
        handleOk={onSubmitDelete}
        handleCancel={() => {
          setVodSelected(null)
          setOpenModalDelete(false)
        }}
        vod={vodSelected}
      />
    </>
  )
}
