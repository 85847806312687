import { all, fork } from 'redux-saga/effects'
import {
  watchDoGetListChannel,
  watchDoCreateChannel,
  watchDoDeleteChannel,
  watchDoGetDetailChannel,
  watchDoUpdateChannel,
  watchDoGetDetailChannelProgram,
  watchDoUpdateChannelProgram,
  watchDoDeleteChannelProgram,
  watchDoAddChannelProgram as watchDoAddChannelProgramInChannel,
} from './channel'
import {
  watchDoGetListProgram,
  watchDoCreateProgram,
  watchDoDeleteProgram,
  watchDoGetDetailProgram,
  watchDoAddChannelProgram,
} from './program'
import watchDoLogin from './authentication'
import {
  watchDoGetListVod,
  watchDoDeleteVod,
  watchDoCreateVod,
  watchDoUpdateVod,
} from './vod'

const rootSaga = function* () {
  yield all([
    fork(watchDoGetListChannel),
    fork(watchDoCreateChannel),
    fork(watchDoLogin),
    fork(watchDoGetListVod),
    fork(watchDoDeleteVod),
    fork(watchDoCreateVod),
    fork(watchDoUpdateVod),
    fork(watchDoDeleteChannel),
    fork(watchDoUpdateChannel),
    fork(watchDoGetDetailChannel),
    fork(watchDoGetListProgram),
    fork(watchDoCreateProgram),
    fork(watchDoDeleteProgram),
    fork(watchDoGetDetailProgram),
    fork(watchDoGetDetailChannelProgram),
    fork(watchDoUpdateChannelProgram),
    fork(watchDoAddChannelProgram),
    fork(watchDoDeleteChannelProgram),
    fork(watchDoAddChannelProgramInChannel),
  ])
}

export default rootSaga
